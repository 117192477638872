var render = function render(_c,_vm){return _c('div',{staticClass:"product-qty"},[_c('div',{staticClass:"rounded-pill qty-wrap",class:{
      'not-empty': _vm.parent.quantity > 0,
      'show-add-btn': _vm.parent.quantity < 1
    },on:{"mousedown":function($event){$event.stopPropagation();}}},[_c('v-btn',{staticClass:"minus rounded",attrs:{"color":"primary","large":"","aria-label":"Diminuisci quantità","depressed":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.parent.minus(
          _vm.props.updateCart,
          { infos: { ..._vm.props.selectedOptions } },
          {
            searchUid: _vm.props.product.searchUid,
            position: _vm.props.position
          }
        )}}},[_c('v-icon',[_vm._v("$minus")])],1),_c('div',{staticClass:"val-cnt",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"mousedown":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"val"},[_vm._v(_vm._s(_vm.parent.quantity)+_vm._s(_vm.parent.unit))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.parent.quantityPerUnit))])]),_c('v-btn',{staticClass:"plus rounded",attrs:{"large":"","aria-label":"Aumenta quantità","depressed":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.parent.plus(
          _vm.props.updateCart,
          {
            infos: {
              ..._vm.props.selectedOptions
            }
          },
          {
            searchUid: _vm.props.product.searchUid,
            position: _vm.props.position
          }
        )}}},[(_vm.parent.quantity > 0)?_c('v-icon',[_vm._v("$plus")]):[_c('v-icon',[_vm._v("$cart")])]],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }