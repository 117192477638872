<template>
  <v-card
    :aria-label="`${$t('article.readArticle')} ${article.slug}`"
    outlined
    class="article-card align-start rounded-lg"
    :class="size"
    :to="to"
  >
    <!-- <ebsn-media
      :cover="true"
      width="100%"
      :height="220"
      :max-height="220"
      :target="article"
      type="Article main"
      media="thumb"
      :alt="article.title"
      cssClass="article-img"
    /> -->
    <v-img
      class="article-card__media w-100"
      cover
      height="220"
      :src="articleMedia"
      alt=""
    />
    <template v-if="size != 'small'">
      <v-chip
        v-if="article.articleType.name == 'recipe'"
        class="article-course ml-4"
      >
        {{ course }}
      </v-chip>
      <div v-if="article.articleType.name == 'post'" class="d-flex ml-auto">
        <v-chip
          v-for="category in categories"
          :key="category.id"
          class="article-course mr-4"
        >
          {{ category.name }}
        </v-chip>
      </div>
    </template>
    <v-card-subtitle
      v-if="article.articleType.name == 'post'"
      class="small--text article-date py-0"
    >
      {{ $dayjs(article.pubDate, "DD/MM/YYYY").format("DD/MM/YYYY") }}
    </v-card-subtitle>
    <v-card-title
      class="font-weight-bold text-left w-100 pt-5 d-flex justify-start align-start article-title"
      >{{ article.title }}</v-card-title
    >
    <!-- <v-card-text v-if="size != 'small'">
      <div
        class="is-content"
        v-if="article.content"
        v-html="article.content"
      ></div>
      <template v-else>
        {{ article.contentAbstract }}
      </template>
    </v-card-text> -->
    <router-link
      :aria-label="`${$t('article.readArticle')} ${article.slug}`"
      class="article-cta d-flex align-end justify-end w-100 h-100"
      :to="{
        path: `/rivista-e-news/${article.slug}`
      }"
    >
      <v-icon color="primary" size="32" class="ml-auto pr-3 pb-3"
        >$arrowForward</v-icon
      >
    </router-link>
  </v-card>
</template>
<style lang="scss">
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .article-card {
    //  max-width: 311px;
  }
}
.article-card {
  height: 420px;
  width: auto;
  display: flex;
  flex-direction: column;
  &__media {
    border-bottom: 1px solid #e0e0e0;
  }
  // .article-img {
  //   border-bottom: 1px solid #d3d3d3;
  // }
  .article-title {
    font-size: 22px !important;
    font-weight: 500 !important;
    line-height: 1.4rem !important;
  }
  .article-cta {
    text-decoration: none;
    .v-icon {
      font-weight: bold;
    }
  }
  .article-date {
    font-size: 16px !important;
    color: #2b2f31;
    font-weight: 400;
  }
  .article-course {
    margin-top: -16px;
    background-color: #ffffff !important;
    color: var(--v-default-base);
    border: 1px solid var(--v-primary-base);
  }
  &.small {
    .v-card__title {
      font-size: 14px;
      font-weight: normal;
    }
  }
  p {
    font-size: 14px;
    padding-bottom: 0;
  }
}
</style>
<script>
import { buildArticleRoute } from "@/service/functions";

export default {
  name: "ArticleCard",
  props: {
    article: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: "normal"
    }
  },
  computed: {
    to() {
      return buildArticleRoute(this.article);
    },
    articleMedia() {
      return this.article.media && this.article.media[0].large
        ? this.article.media[0].large
        : "/no-icon.png";
    },
    isCardInHomePage() {
      return this.$route.path === "/";
    },
    imgHeight() {
      if (this.size == "small") {
        return 100;
      } else {
        return 220;
      }
    },
    course() {
      return this.article?.articleClass?.find(
        ac => ac.articleClassGroup.name == "course"
      )?.name;
    },
    categories() {
      return this.article?.articleClass?.filter(
        ac => ac.articleClassGroup.name == "category"
      );
    }
  }
};
</script>
