const videoURLMatcher = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(embed|shorts|watch)(?:.*v=|v\/|\/)([\w\-_]+)&?/;
function decodeVideoEmbedURL(videoUrl) {
  if (videoUrl) {
    let decodedURL = videoURLMatcher.exec(videoUrl);
    const youtubeEmbedTemplate =
      "https://www.youtube.com/embed/" +
      decodedURL[2] +
      "?&autoplay=1&controls=0";
    return youtubeEmbedTemplate;
    // const url = videoUrl.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    // const YId =
    //   undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
    // if (YId === url[0]) {
    //   return videoUrl;
    // } else {
    //   const topOfQueue = youtubeEmbedTemplate.concat(YId);
    //   return topOfQueue;
    // }
  } else {
    return null;
  }
}
function safeStorage() {
  // Check if localStorage is available
  try {
    const testKey = "__test__";
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    // If localStorage is available, return the localStorage object
    return localStorage;
  } catch (e) {
    // If an error occurs (localStorage is not available), use sessionStorage
    return sessionStorage;
  }
}
export { videoURLMatcher, decodeVideoEmbedURL, safeStorage };
