<template>
  <div class="cooperatives pa-4 rounded-md w-100 d-flex flex-column px-0">
    <CooperativesFilter
      @addressToSearchChanged="addressToSearchChanged"
      @resetNearWarehouse="resetNearWarehouse"
      @filterCooperatives="filterCooperatives"
    />

    <div class="w-100" v-if="!loading">
      <v-btn
        outlined
        color="primary"
        v-if="filtered"
        class="my-4 text-center"
        @click="clearFilter"
        >{{ $t("cooperatives.filter.clear") }}</v-btn
      >
      <v-list nav :key="coopKey" v-if="cooperativesList">
        <v-list-item
          v-for="cooperative in cooperativesList"
          :key="cooperative.articleId"
          two-line
          dense
          @click="goToCooperative(cooperative)"
        >
          <v-list-item-content>
            <v-list-item-title
              class="mb-3 default--text text-capitalize text-left font-weight-medium"
            >
              {{ cooperative.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $ebsn.meta(cooperative, "articletype.ADDRESS") }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-icon class="font-weight-bold" color="primary"
            >$arrowForward</v-icon
          >
        </v-list-item>
      </v-list>
      <v-pagination
        circle
        v-if="
          !filtered && pager && pager.totPages > 1 && !$vuetify.breakpoint.xs
        "
        :value="pageFilter"
        :page="pager.selPage"
        :length="pager.totPages ? pager.totPages : 0"
        :totalVisible="7"
        @next.stop.prevent="handlePageFilter"
        @prev="handlePageFilter"
        @input="handlePageFilter"
        class="py-6"
        prev-icon="$arrowBack"
        next-icon="$arrowForward"
        color="primary"
      ></v-pagination>
    </div>
    <div v-else>
      <v-row>
        <v-col v-for="index in 6" :key="index" cols="12">
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <p v-if="!loading && cooperativesList.length == 0">
      {{ $t("cooperatives.noResult") }}
    </p>
  </div>
</template>
<style lang="scss" scoped>
.cooperatives {
  .v-list-item {
    &:not(:last-child) {
      border-bottom: 1px solid var(--v-grey-lighten3);
    }
  }
  .v-list-item__title {
    text-transform: uppercase;
    color: var(--v-primary-base);
    font-size: 14px;
    font-weight: bold;
  }
  .v-list-item__subtitle {
    color: $text-color;
  }
}
</style>
<script>
import CooperativesFilter from "@/components/cooperatives/CooperativesFilter.vue";
import { mapGetters } from "vuex";
import mksCmService from "@/commons/service/mksCmService";
import AddressService from "@/commons/service/addressService";
export default {
  name: "Cooperatives",
  components: { CooperativesFilter },
  data() {
    return {
      cooperativesList: null,
      pager: {},
      page: 1,
      loading: false,
      distanceRange: 10000,
      coopKey: 1,
      filtered: false,
      addressToSearch: null
      //showNearWarehouse: false
    };
  },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    }),
    totItems() {
      return this.pager && this.pager.totItems ? this.pager.totItems : 0;
    }
  },
  methods: {
    async clearFilter() {
      this.addressToSearch = null;
      await this.getCooperativesList();
      this.filtered = false;
    },
    async loadMore() {
      if (this.hasMoreItems) {
        this.$store.dispatch("category/setFilterPage", this.pageFilter + 1);
        await this.getCooperativesList(true);
      }
    },
    handlePageFilter(page) {
      //  var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      this.getCooperativesList();
      //oppure mettere goTo(0) dentro timeout
      //  setTimeout(function() {
      //   _this.$vuetify.goTo(0);
      //  }, 300);
    },
    async getCooperativesList() {
      console.log("bbb doing search now", this.addressToSearch);
      if (
        this.addressToSearch &&
        this.addressToSearch?.latitude &&
        this.addressToSearch.longitude &&
        this.addressToSearch.distance
      ) {
        const searchParams = {
          parent_latitude: this.addressToSearch.latitude,
          parent_longitude: this.addressToSearch.longitude,
          parent_distance: this.addressToSearch.distance
        };

        let data = await AddressService.findWarehouse(searchParams);
        const companyIds = [...new Set(data.warehouses.map(w => w.companyId))];
        const filteredCooperatives = this.cooperativesList.filter(article =>
          companyIds.includes(article.metaData?.articletype?.COMPANY_ID)
        );
        setTimeout(() => {
          this.cooperativesList = filteredCooperatives;
          this.filtered = true;
        }, 300);
        this.coopKey++;
      }
      this.loading = true;
      try {
        let result = await mksCmService.searchArticle({
          parent_article_type_id: 3,
          page: this.pageFilter,
          page_size: 10
        });
        if (result) {
          console.log("result ", result);
          this.cooperativesList = result.data.articles;
          this.pager = result.data.page;
          this.loading = false;
        }
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: global.EventBus.$t("errors.general")
        });
        return null;
      }
    },
    goToCooperative(cooperative) {
      this.$router.push({
        name: "CooperativeDetail",
        params: { slug: cooperative.slug }
        //query: { slug: cooperative.slug }
      });
    },
    async resetNearWarehouse() {
      this.addressToSearch = null;
      await this.getCooperativesList();
    },
    async addressToSearchChanged(addressToSearch) {
      console.log("bbb emit captured");
      this.addressToSearch = addressToSearch;
      await this.getCooperativesList();
    },
    async filterCooperatives(q) {
      const res = await mksCmService.searchArticle({
        parent_article_type_id: 3, // Cooperatives
        q
      });
      if (res?.response?.status === 0) {
        this.cooperativesList = res.data.articles;
      }
    }
  },
  async created() {
    await this.getCooperativesList();
  }
};
</script>
