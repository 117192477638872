<template>
  <div style="position:relative" class="leaflet-slider">
    <swiper
      class="py-3"
      ef="swiperRef"
      :options="
        $ebsn.meta(
          config,
          'metaData.categoryblocktype_LeafletSliderWrapper.JSON_CONFIG',
          swiperOptions
        )
      "
    >
      <swiper-slide
        class="d-flex flex-column h-100"
        v-for="leaflet in leaflets"
        :key="leaflet.leafletId"
      >
        <div class="leaflet-descr mb-2">
          <h4 class="leaflet-title my-0">
            {{ leaflet.title }}
          </h4>
          <span class="leaflet-subtitle">
            {{ $t("leaflet.validity.from") }}
            {{ leaflet.fromDate | dayjs("format", "D  MMMM") }}
            {{ $t("leaflet.validity.to") }}
            {{ leaflet.toDate | dayjs("format", "D  MMMM") }}</span
          >
        </div>
        <v-card
          :aria-label="`${$t('leaflet.title')} ${leaflet.title}`"
          class="fill-height leaflet-card rounded-md elevation-3 pa-3"
          :to="{
            name: 'Leaflet',
            params: {
              leafletId: leaflet.slug
                ? leaflet.slug
                : leaflet?.leafletId?.toString()
            }
          }"
        >
          <v-img
            contain
            class="leaflet-card__image"
            width="230px"
            height="220px"
            :src="`${leaflet.baseLocation}${leaflet.firstPagePreview}`"
          />
          <!-- <v-btn
            large
            class="w-100 mt-3 elevation-0 rounded-pill"
            color="primary"
          >
            <span class="font-weight-bold">{{
              $t("leaflet.navigation.discover")
            }}</span>
          </v-btn> -->
        </v-card>
      </swiper-slide>
    </swiper>
    <swiper-paginator
      :showBullets="showBullets"
      :showArrows="showArrows"
      :paginationClass="paginationClass"
      :length="leaflets.length"
    />
    <!-- <h1>
      SONO PIPP {{ showBullets }} - {{ showArrows }} - {{ paginationClass }}
      {{ config?.categoryBlockId }}
    </h1> -->
    <!-- <div class="swiper-button-next">
      <v-icon color="primary">icon-arrow-forward</v-icon>
    </div>
    <div class="swiper-button-prev">
      <v-icon color="primary">icon-arrow-back</v-icon>
    </div> -->
  </div>
</template>
<style lang="scss">
.leaflet-slider {
  .swiper-slide {
    width: 200px;
    max-width: 200px;
  }
  .leaflet-descr {
    min-height: 123px !important;
    .leaflet-title {
      font-size: 18px;
      font-weight: 500;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      //  line-height: 0 !important;
    }
    .leaflet-subtitle {
      font-size: 14px;
      font-weight: 500;
      color: #414548;
    }
  }
}
.leaflets-container {
  .leaflet-card {
    &__image {
      // width: 230px;
      // height: 220px;
    }
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .leaflet-descr {
    h3 {
      line-height: 1.7rem !important;
      height: 75px;
    }
  }
}
</style>
<script>
import leafletService from "~/service/leafletService.js";
import get from "lodash/get";
import SwiperPaginator from "@/components/SwiperPaginator.vue";

export default {
  name: "LeafletsSlider",
  components: { SwiperPaginator },
  data() {
    return {
      name: "LeafletSliderWrapper",
      paginationClass: "leafletSlider",
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        loop: false,
        // breakpoints: {
        //   0: {
        //     slidesPerView: 2
        //   },
        //   600: {
        //     slidesPerView: 2
        //   },
        //   960: {
        //     slidesPerView: 3
        //   },
        //   1264: {
        //     slidesPerView: 4
        //   },
        //   1900: {
        //     slidesPerView: 4
        //   }
        // },
        pagination: {
          el: `#pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#prev-${this.paginationClass}`,
          nextEl: `#next-${this.paginationClass}`
        }
      },
      leaflets: [],
      showBullets: false,
      showArrows: false
    };
  },
  props: {
    parentWarehouseId: { type: Number, required: false },
    parentLeafletTypeId: { type: Array, required: true },
    config: {
      type: Object,
      required: false
    }
  },
  async mounted() {
    this.showBullets = get(
      this.config,
      "metaData.categoryblocktype_" + this.name + ".SHOW_BULLETS",
      true
    );

    this.showArrows = get(
      this.config,
      "metaData.categoryblocktype_" + this.name + ".SHOW_ARROWS",
      false
    );

    if (this?.config?.categoryBlockId) {
      this.paginationClass = "leaflet-" + this.config.categoryBlockId;
    }

    this.leaflets = await leafletService.search({
      parent_warehouse_id: this.parentWarehouseId,
      parent_leaflet_type_id: this.parentLeafletTypeId
    });
    // order and table
    const sortOrder = [10, 4, 7, 3, 9, 5, 8, 6];
    const sortOrderObject = sortOrder.reduce((table, id, index) => {
      table[id] = index;
      return table;
    }, {});
    // sort
    this.leaflets.sort((a, b) => {
      const idA = a.leafletType.leafletTypeId;
      const idB = b.leafletType.leafletTypeId;
      // if leaflettypeid not found send it to the end
      const indexA = sortOrderObject[idA] ?? Infinity;
      const indexB = sortOrderObject[idB] ?? Infinity;

      return indexA - indexB;
    });

    // if (this.leaflets.length == 1) {
    //   this.$router.push({
    //     name: "Leaflet",
    //     params: {
    //       leafletId: this.leaflets[0].slug
    //         ? this.leaflets[0].slug
    //         : this.leaflets[0].leafletId.toString()
    //     }
    //   });
    // }
  }
};
</script>
