<template>
  <v-card
    :href="link"
    @click.prevent="handleLink"
    :style="cardStyle"
    class="proposal-card-overlay"
    v-intersect.once="handleView"
  >
    <div class="video-container" v-if="videoSrc">
      <iframe :src="videoSrc" :title="proposal.name"></iframe>
    </div>
    <div v-else>
      Invalid YouTube URL
    </div>
  </v-card>
</template>
<style lang="scss">
.proposal-card-overlay.v-card {
  overflow: hidden;
  height: 100%;
  .video-container {
    iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      height: 100vh;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
  }
  .blurred-bg:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 30%, black 100%);
  }
  .v-card__title,
  .v-card__text {
    color: var(--v-white-base);
  }
  .v-image {
    height: 100%;
    color: inherit;
  }
  .text-overlay {
    bottom: 0px;
    position: absolute;
    width: 100%;
  }
}
</style>
<script>
import banner from "~/mixins/banner";

import get from "lodash/get";
// import EmbedVideo from "@/commons/components/EmbedVideo.vue";

export default {
  // components: { EmbedVideo },
  name: "ProposalVideo",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    cardStyle() {
      let style = {};
      let backgroundColor = get(
        this.proposal,
        `metaData.category_proposal_type.BACKGROUND_COLOR`
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
      }
      let textColor = get(
        this.proposal,
        `metaData.category_proposal_type.TEXT_COLOR`
      );
      if (textColor) {
        style.color = textColor;
      }

      return style;
    },
    videoSrc() {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "VIDEO_DESKTOP" : "VIDEO_MOBILE");
      const url = get(this.proposal, prop, this.proposal.video);

      // eslint-disable-next-line no-useless-escape
      const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+|(?:v|e(?:mbed)?)\/|(?:.*[?&]v=))|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

      const match = url.match(regex);

      if (match) {
        const videoId = match[1];
        return `https://www.youtube.com/embed/${videoId}?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=${videoId}`;
      }
      return false;

      //   return "https://www.youtube.com/watch?v=CzKWwwCGqkU&ab_channel=CoopCentroItalia";
    }
  }
};
</script>
