<template>
  <div style="position: relative;">
    <div class="article-slider-container h-inherit mb-5">
      <ebsn-meta
        :target="config"
        path="categoryblocktype_ArticleSlider.DESCRIPTION"
        tag="div"
      />
      <div class="row d-flex flex-column flex-md-row flex-md-nowrap">
        <v-img
          v-if="magazineCover"
          eager
          max-width="360"
          :src="magazineCover"
          :class="$vuetify.breakpoint.mdAndDown ? 'col col-12' : 'col col-md-3'"
          class=" magazine-cover d-flex align-end justify-center border outlined rounded-md"
        >
          <div class="d-flex">
            <v-btn
              depressed
              large
              color="primary"
              class="rounded-pill mb-3 mx-auto"
              to="/rivista"
              >{{ $t("leaflet.goToMagazine") }}</v-btn
            >
          </div>
        </v-img>
        <div class="d-flex flex-column ml-md-3 news-section">
          <div class="d-lg-flex align-center">
            <div class="d-flex flex-column" style="max-width: fit-content;">
              <h3 class="mr-auto">{{ title }}</h3>
              <h2 class="mr-auto">{{ subtitle }}</h2>
            </div>
            <v-btn
              :aria-label="subtitle"
              text
              depressed
              class="show-all pr-0 ml-lg-auto d-flex justify-end"
              to="/rivista-e-news"
              >{{ $t("articles.post.goToAll") }}
              <v-icon color="primary" size="25" class="font-weight-bold ml-1"
                >$arrowForward</v-icon
              ></v-btn
            >
          </div>

          <div
            class="mt-auto slider article-slider h-inherit"
            v-if="articleList && articleList.length > 0"
          >
            <swiper :options="swiperOption" ref="swiperRef" class="h-inherit">
              <swiper-slide
                v-for="article in articleList"
                :key="article.articleId"
                class="swiper-slider-article-card"
              >
                <ArticleCard :article="article" />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <swiper-paginator
      :showBullets="
        $ebsn.meta(config, 'categoryblocktype_ArticleSlider.SHOW_BULLETS', true)
      "
      :showArrows="
        $ebsn.meta(config, 'categoryblocktype_ArticleSlider.SHOW_ARROWS', false)
      "
      :paginationClass="`banner-${config.categoryBlockId}`"
      :length="articleList?.length"
    />
  </div>
</template>
<style lang="scss">
.article-slider-container {
  .news-section {
    width: 100%;
  }
  .swiper-slide {
    // width: 420px !important;
  }
  .swiper-slide.swiper-slider-article-card {
    height: auto;
  }
  .article-card {
    height: 100%;
  }
  .show-all {
    text-transform: initial !important;
    color: #3d3d3d;
    font-size: 16px;
    font-weight: 500;
  }
  .magazine-cover {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
}
@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .article-slider-container {
    .news-section {
      max-width: calc(100% - 360px);
    }
  }
}
</style>
<script>
import ArticleCard from "@/components/article/ArticleCard.vue";
import SwiperPaginator from "../SwiperPaginator.vue";

import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

import mksCmService from "~/service/mksCmService";
import leafletService from "~/service/leafletService.js";

export default {
  name: "ArticleSlider",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  components: {
    ArticleCard,
    SwiperPaginator
  },
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      articleList: null,
      swiperRef: null,
      swiperDefaultOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        loop: false,
        pagination: {
          el: `#pagination-banner-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#prev-banner-${this.config.categoryBlockId}`,
          nextEl: `#next-banner-${this.config.categoryBlockId}`
        }
      },
      title: null,
      subtitle: null,
      articleClassFilter: null,
      articleSort: null,
      articleType: null,
      limit: null,
      magazineCover: null,
      magazineLink: null
    };
  },
  methods: {
    async reload() {
      let _this = this;
      let parentArticleTypeId = this.$ebsn.meta(
        this.config,
        "categoryblocktype_ArticleSlider.ARTICLE_TYPE"
      );

      // let parentArticleClassId = this.$ebsn.meta(
      //   this.config,
      //   "categoryblocktype_ArticleSlider.ARTICLE_CLASS_FILTER"
      // );
      let sort = this.$ebsn.meta(
        this.config,
        "categoryblocktype_ArticleSlider.ARTICLE_SORT"
      );
      let limit = this.$ebsn.meta(
        this.config,
        "categoryblocktype_ArticleSlider.LIMIT"
      );
      let params = {
        parent_article_type_id: parentArticleTypeId,
        // parent_article_class_id: parentArticleClassId,
        sort: sort,
        page_size: limit ? limit : 8
      };

      let content = await mksCmService.searchArticle(params);
      this.articleList = content?.data?.articles;
      _this.$nextTick(() => {
        if (_this.$refs.swiperRef && _this.$refs.swiperRef.swiperInstance) {
          _this.$refs.swiperRef.swiperInstance.slideTo(0);
        }
      });
      if (!this.articleList || this.articleList.length < 1) {
        this.$emit("hide");
      }
    },
    async fetchMagazines() {
      const magazines = await leafletService.search({
        parent_leaflet_type_id: 2
      });
      if (magazines && magazines.length > 0) {
        const latestMagazine = magazines[0];
        this.magazineLink = "/volantini/" + latestMagazine.leafletId;
        this.magazineCover =
          latestMagazine.baseLocation + latestMagazine.firstPagePreview;
      }
    }
  },
  async mounted() {
    await this.reload();
    await this.fetchMagazines();
    this.title = this.$ebsn.meta(
      this.config,
      "categoryblocktype_ArticleSlider.INFO_TITLE"
    );
    this.subtitle = this.$ebsn.meta(
      this.config,
      "categoryblocktype_ArticleSlider.INFO_SUBTITLE"
    );
  }
};
</script>
