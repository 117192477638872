import Vue from "vue";
import dayjs from "dayjs";
import it from "dayjs/locale/it";
// eslint-disable-next-line no-unused-vars
import de from "dayjs/locale/de";
import customParseFormat from "dayjs/plugin/customParseFormat";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import weekday from "dayjs/plugin/weekday";
import store from "@/store";
dayjs.extend(weekday);
dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);
//dayjs.locale(it);

let languageValue = store.getters["app/getLanguage"] || it;
dayjs.locale(languageValue);
dayjs.extend(duration);
dayjs.duration(100);

Object.defineProperties(Vue.prototype, {
  $dayjs: {
    get() {
      return dayjs;
    }
  }
});
const InvalidReturn = "";

Vue.filter("dayjs", (value, method, ...params) => {
  let d = dayjs(value);
  if (!d.isValid()) return InvalidReturn;
  if (method) {
    return d[method].apply(d, params);
  }
});
