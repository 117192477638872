<template>
  <div
    class="article-detail-wrapper"
    v-if="article && article.content"
    @click="clicked"
  >
    <v-runtime-template :template="template" />
  </div>
</template>
<script>
import cmService from "~/service/mksCmService";
import category from "@/commons/mixins/category";
import VRuntimeTemplate from "v-runtime-template";
import clickHandler from "~/mixins/clickHandler";
import InstitutionalCard from "../article/InstitutionalCard.vue";
/* eslint-disable vue/no-unused-components */
import {
  VImg,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VTabs,
  VTabsSlider,
  VTab,
  VTabsItems,
  VTabItem,
  VAlert,
  VTooltip,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VDivider,
  VList,
  VListItem,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VDialog,
  VContainer,
  VRow,
  VCol
} from "vuetify/lib";

export default {
  name: "ArticleDetailBlock",
  data() {
    return {
      article: null,
      tab: null
    };
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    VRuntimeTemplate,
    InstitutionalCard,
    VImg,
    VBtn,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VTabs,
    VTabsSlider,
    VTab,
    VTabsItems,
    VTabItem,
    VAlert,
    VTooltip,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VDivider,
    VList,
    VListItem,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    VDialog,
    VContainer,
    VRow,
    VCol
  },
  computed: {
    template() {
      if (this.article) {
        return `<div>${this.article.content}</div>`;
      } else {
        return null;
      }
    }
  },
  mixins: [category, clickHandler],
  props: { config: { type: Object, required: true } },
  methods: {
    async reload() {
      let article_id = this.$ebsn.meta(
        this.config,
        "categoryblocktype_ArticleDetail.ARTICLE_ID"
      );

      let params = {};
      if (!article_id) {
        params = {
          slug: this.category.slug
        };
      } else {
        params = {
          article_id
        };
      }

      let content = await cmService.getArticle(params);
      this.article = content?.data;
      this.key++;
    }
  },
  created() {
    this.reload();
  }
};
</script>
