var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.handleView),expression:"handleView",modifiers:{"once":true}}],staticClass:"proposal-card-overlay",style:(_vm.cardStyle),attrs:{"href":_vm.link},on:{"click":function($event){$event.preventDefault();return _vm.handleLink.apply(null, arguments)}}},[_c('v-img',{attrs:{"src":_vm.imgSrc,"alt":_vm.proposal.imgAlt,"title":_vm.proposal.imgDescription,"eager":""}},[(
        _vm.$ebsn.meta(
          _vm.proposal,
          'metaData.category_proposal_type.SHOW_BLACK_LAYER'
        )
      )?_c('div',{staticClass:"blurred-bg"}):_vm._e(),_c('div',{staticClass:"text-overlay",class:_vm.$ebsn.meta(
          _vm.proposal,
          'metaData.category_proposal_type.TEXT_ALIGN',
          ' '
        )},[(_vm.$ebsn.meta(_vm.proposal, 'metaData.category_proposal_type.NAME'))?_c('v-card-title',{domProps:{"innerHTML":_vm._s(_vm.$ebsn.meta(_vm.proposal, 'metaData.category_proposal_type.NAME'))}}):_vm._e(),(
          _vm.$ebsn.meta(_vm.proposal, 'metaData.category_proposal_type.DESCRIPTION')
        )?_c('v-card-text',{domProps:{"innerHTML":_vm._s(
          _vm.$ebsn.meta(_vm.proposal, 'metaData.category_proposal_type.DESCRIPTION')
        )}}):_vm._e(),(
          _vm.$ebsn.meta(_vm.proposal, 'metaData.category_proposal_type.BTN_TEXT')
        )?_c('v-card-actions',[_c('v-btn',{attrs:{"aria-label":_vm.$ebsn.meta(_vm.proposal, 'metaData.category_proposal_type.BTN_TEXT'),"href":_vm.link,"outlined":_vm.$ebsn.meta(
              _vm.proposal,
              'metaData.category_proposal_type.BTN_OUTLINED',
              false
            ),"color":_vm.$ebsn.meta(_vm.proposal, 'metaData.category_proposal_type.BTN_COLOR')}},[_vm._v(_vm._s(_vm.$ebsn.meta(_vm.proposal, "metaData.category_proposal_type.BTN_TEXT")))])],1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }