let routes = [
  {
    path: "/volantini",
    meta: {
      site: "sait"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "Leaflets",
        meta: {
          site: "sait",
          slug: "leaflet"
          // pageName: "leaflets"
        },
        component: () => import("@/views/catalog/Leaflets.vue")
      },
      {
        path: "/volantini/:leafletId",
        name: "Leaflet",
        meta: {
          site: "sait"
          // slug: "leaflet"
        },
        props: route => ({
          leafletId: route.params.leafletId
        }),
        component: () => import("@/views/catalog/Leaflet.vue")
      },
      {
        path: "/volantini/:leafletId/lista",
        name: "LeafletList",
        meta: {
          site: "sait",
          slug: "leaflet-list"
          // ,
          // pageName: "leaflet-list"
        },
        props: route => ({
          leafletId: route.params.leafletId
        }),
        component: () => import("@/views/catalog/LeafletList.vue")
      }
    ]
  },
  {
    path: "/leaflet/:leafletId/:page",
    name: "LeafletProduct",
    meta: {
      site: "sait"
    },
    props: route => ({
      productId: route.query.product_id,
      leafletId: route.params.leafletId,
      page: route.params.page
    }),
    component: () => import("@/views/catalog/LeafletProduct.vue")
  },
  {
    //gestione gerarchica del catalogo per poter gestire il reset filters all'ingresso.
    path: "/catalog",
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "/search",
        name: "Search",
        component: () => import("@/views/catalog/Search.vue"),
        props: route => ({
          query: route.params,
          parent_category_id: route.params.parent_category_id,
          filter: route.params.filter,
          mode: "search"
        }),
        meta: { slug: "search" }
      },
      {
        path: "/fast-search",
        name: "FastSearch",
        component: () => import("@/views/catalog/FastSearch.vue"),
        props: route => ({
          query: route.query.q
        }),
        meta: { slug: "fast-search" }
      },
      {
        path: "/product/:slug",
        name: "Product",
        component: () => import("@/views/catalog/Product.vue"),
        props: true
      },
      {
        path: "/*",
        name: "Category",
        component: () => import("@/views/catalog/Category.vue"),
        meta: {
          keepAlive: true
        },
        //commento
        props: route => ({
          slug: route.params.pathMatch,
          categoryId: route.query.categoryId
        })
      }
    ]
  },
  {
    path: "/le-cooperative",
    meta: {
      breadCrumb: "le cooperative",
      site: "sait"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    }
  },
  {
    path: "/rivista",
    meta: {
      breadCrumb: "La Rivista",
      site: "sait",
      slug: "rivista"
    },
    component: () => import("@/components/category/magazine.vue")
  }
];

export default routes;
