<template>
  <v-container
    class="address-selector-container fill-height default--text px-3"
  >
    <v-layout class="fill-height column mt-5 text-center">
      <ebsn-meta
        :target="category"
        path="category_info.TITLE"
        class="mx-auto text-center"
        tag="h1"
      ></ebsn-meta>
      <ebsn-meta
        :target="category"
        path="category_info.DESCRIPTION"
        class="text-center mt-2"
      ></ebsn-meta>
      <category-block
        :target="category"
        position="position1"
        class="category-block category-block-1"
        :container="false"
      />
      <v-row class="justify-center align-center my-3 tabs">
        <v-col
          :cols="deliveryServiceButtonsLength"
          class="px-2"
          v-for="(service, idx) in deliveryServices"
          :key="service?.deliveryServiceId"
          @click="fetchAddresses(service, idx)"
        >
          <div
            class="tab rounded-md d-flex flex-column align-center justify-center flex-grow-1 py-3 py-sm-1 rounded-sm"
            :class="
              service?.deliveryServiceId === selectedDeliveryServiceId
                ? `${service.cssClass} selected grey lighten-3`
                : `${service.cssClass}`
            "
          >
            <div class="d-flex flex-column justify-center align-center">
              <v-img
                :src="service.iconSource"
                :alt="'Immagine' + service.name"
                contain
                class="mr-1"
                :height="$vuetify.breakpoint.xs ? 60 : 44"
                :width="$vuetify.breakpoint.xs ? 70 : 44"
              >
              </v-img>
              <h4 class="secondary--text">{{ service.name }}</h4>
              <span class="secondary--text">{{ service.descr }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-card :loading="loading" flat>
        <v-list dense class="address-list pt-0">
          <v-list-item
            v-if="
              deliveryService?.deliveryServiceId == 2 &&
                eldersAddressEnabled &&
                !eldersInfoRead &&
                eldersAddress == null &&
                !loading
            "
          >
            <v-alert type="success">
              {{ $t("addressSelector.elder.explenation") }}
              <v-list-item-action class="d-block">
                <v-btn
                  @click="eldersInfoRead = true"
                  color="secondary"
                  depressed
                >
                  {{ $t("addressSelector.elder.explenationAccept") }}
                </v-btn>
              </v-list-item-action>
            </v-alert>
          </v-list-item>
          <v-list-item-group
            v-else-if="!loading"
            v-model="shippingAddress"
            color="secondary"
          >
            <div
              class="address-filter-container d-flex flex-column"
              v-if="deliveryService?.deliveryServiceId == 1"
            >
              <h5 class="text-left text-uppercase">cerca un punto vendita</h5>
              <v-row class="ma-0">
                <v-col cols="12" sm="7">
                  <vue-google-autocomplete
                    id="pacman-input"
                    classname="w-100 rounded-pill grey lighten3 pa-5 google-autocomplete-input address-to-search"
                    ref="addresstosearchref"
                    :placeholder="
                      $t(
                        'navbar.service.' +
                          selectedDeliveryServiceId +
                          '.searchLabel'
                      )
                    "
                    icon="$search"
                    :types="'geocode'"
                    v-on:placechanged="addressToSearchChanged"
                    :enable-geolocation="true"
                    country="it"
                    v-if="googleEnabled"
                  >
                  </vue-google-autocomplete
                ></v-col>
                <v-col cols="12" sm="5">
                  <!-- v-if="showNearWarehouse" -->
                  <v-btn
                    style="height: 100%; min-height: 60px"
                    x-large
                    class="show-new-warehouse fill-height rounded-pill"
                    outlined
                    block
                    color="secondary"
                    text
                    @click="geolocate()"
                  >
                    <v-icon>$locationPointer</v-icon>
                    <span>cerca nelle vicinanza</span>
                  </v-btn>
                  <!-- <v-btn
                    style="height: 100%"
                    x-large
                    class="show-new-warehouse fill-height rounded-pill"
                    outlined
                    block
                    color="secondary"
                    text
                    @click="geolocate()"
                    v-else
                  >
                    <v-icon>$location</v-icon>
                    <span>{{ $t("profile.addresses.geolocalize") }}</span>
                  </v-btn> -->
                </v-col>
              </v-row>

              <!-- <div
                class="d-flex align-start align-sm-center flex-column flex-sm-row mt-4 mt-sm-0 filter-text"
              >
                Raggio di ricerca:
                <v-radio-group
                  class="ml-1"
                  dense
                  v-model="distanceRange"
                  row
                  @change="distanceRangeChanged()"
                >
                  <v-radio color="secondary" :value="10000">
                    <template v-slot:label>
                      <span class="distance-label distance-radio-label">
                        10km
                      </span>
                    </template>
                  </v-radio>
                  <v-radio color="secondary" :value="30000">
                    <template v-slot:label>
                      <span class="distance-label distance-radio-label">
                        30km
                      </span>
                    </template>
                  </v-radio>
                 
                </v-radio-group>
              </div> -->
            </div>

            <div class="warehouse-list-container">
              <v-list-item
                v-for="address in filteredAddresses"
                :key="address.addressId"
                @click="setAddress(address)"
                class="pa-4 warehouse-row"
              >
                <v-icon class="mr-2 ml-2 ml-sm-2" color="secondary">
                  {{ address.selected ? "$radioOn" : "$radioOff" }}
                </v-icon>
                <v-list-item-content class="ml-3">
                  <v-list-item-title
                    class="text-left secondary--text font-weight-bold text-caption text-sm-body-2 mb-1"
                  >
                    {{ address.addressName }}
                  </v-list-item-title>
                  <span
                    class="text-left default--text font-weight-regular text-sm-body-2"
                  >
                    {{
                      $t(
                        "navbar.address." + address.addressTypeId + ".list",
                        address
                      )
                    }}
                  </span>
                </v-list-item-content>
                <v-list-item-action class="flex-row align-center ml-0">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        v-if="address.addressType == 'work'"
                        >$work</v-icon
                      >
                    </template>
                    <span>Indirizzo di consegna al lavoro</span>
                  </v-tooltip>

                  <template
                    v-if="
                      deliveryService?.deliveryServiceId == 2 &&
                        eldersAddressEnabled &&
                        address.addressType != 'work'
                    "
                  >
                    <v-tooltip left v-if="eldersAddress == null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="secondary"
                          depressed
                          small
                          @click.stop.prevent="setAsEldersAddress(address)"
                          >{{
                            $t("addressSelector.elder.selectAddressBtn")
                          }}</v-btn
                        >
                      </template>
                      <span>
                        {{ $t("addressSelector.elder.selectAddressTooltip") }}
                      </span>
                    </v-tooltip>
                    <v-tooltip
                      left
                      v-else-if="eldersAddress.addressId == address.addressId"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-1" v-bind="attrs" v-on="on"
                          >$elder</v-icon
                        >
                      </template>
                      <span>
                        {{ $t("addressSelector.elder.elderIconTooltip") }}
                      </span>
                    </v-tooltip>
                  </template>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        v-if="
                          address.addressId ==
                            cart.user.defaultStoreAddressId ||
                            address.addressId ==
                              cart.user.defaultDeliveryAddressId
                        "
                        >$favourite</v-icon
                      >
                    </template>
                    <span>{{
                      address.addressType == "home"
                        ? $t("addressSelector.usualAddress")
                        : $t("addressSelector.usualPdv")
                    }}</span>
                  </v-tooltip>

                  <template v-if="address.addressClass.length > 0">
                    <AddressClass
                      v-for="addressClass in filteredAddressClasses(
                        address.addressClass
                      )"
                      :key="addressClass['address-class-id']"
                      :addressClass="addressClass"
                    />
                  </template>
                  <!-- <v-btn
                    icon
                    class="no-border"
                    :small="$vuetify.breakpoint.xs"
                    @click.stop="edit(address)"
                    v-if="address.addressType == 'home'"
                  >
                    <v-icon>$edit</v-icon>
                  </v-btn> -->
                  <!-- <v-btn
                    :small="$vuetify.breakpoint.xs"
                    icon
                    class="no-border"
                    @click.stop="remove(address)"
                    v-if="address.addressType == 'home'"
                  >
                    <v-icon>$delete</v-icon>
                  </v-btn> -->
                  <v-btn
                    icon
                    class="ml-3 set-fav-wh"
                    :small="$vuetify.breakpoint.xs"
                    @click.stop="setFavWh(address)"
                    v-if="
                      deliveryService.deliveryServiceId == 1 &&
                        userData?.defaultStoreAddress?.addressId !==
                          address.addressId
                    "
                    ><v-icon color="secondary">$star</v-icon></v-btn
                  >
                  <v-icon
                    v-if="
                      userData?.defaultStoreAddress?.addressId ===
                        address.addressId
                    "
                    color="#FFB502"
                    >$starFull</v-icon
                  >
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="
              deliveryService?.deliveryServiceId === 2 &&
                filteredAddresses.length == 0
            "
            large
            depressed
            color="secondary"
            :to="{ name: 'Page', params: { pageName: 'i-servizi-tigros' } }"
            @click="$emit('submit', false)"
            align="center"
          >
            i nostri servizi
          </v-btn>

          <v-btn
            v-if="deliveryService?.deliveryServiceId === 2"
            color="secondary"
            large
            depressed
            :to="{ name: 'EditAddresses' }"
            @click="$emit('submit', false)"
          >
            AGGIUNGI INDIRIZZO
          </v-btn>
        </v-card-actions>
      </v-card>
      <category-block
        :target="category"
        position="position2"
        class="category-block category-block-2"
        :container="false"
      />
    </v-layout>
  </v-container>
</template>
<style lang="scss">
.address-filter-container {
  #address-filter-manual {
    border: 1px solid var(--v-primary-base) !important;
  }
  .show-new-warehouse {
    border: 1px solid var(--v-secondary-base) !important;
  }
  .google-autocomplete-input-container {
    display: flex;

    border: 1px solid #707070;
    border-radius: $border-radius-root;
    padding: 3px 6px 3px 15px;
    font-size: 14px;
    .google-autocomplete-input {
      width: inherit;
      &:focus {
        outline: none;
      }
    }
  }
}
.address-edit-container {
  .section-title {
    font-size: 20px;
  }
  .add-address-form {
    #map {
      width: 100%;
      border: 1px solid var(--v-grey-base);
      border-radius: 5px;
      padding: 6px;
    }
  }
  .missing-address-data {
    // color: $warning;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .address-actions {
      .v-btn {
        width: 100%;
      }
    }
  }
  .v-autocomplete {
    .v-input__append-inner {
      display: none;
    }
  }
  .v-input--switch {
    .v-input__slot {
      background-color: transparent !important;
    }
  }
  .mandatory {
    font-size: 14px;
  }
}
.warehouse-row {
  position: relative;
}

// .set-fav-wh {
//   visibility: hidden;
// }

.warehouse-row:hover .set-fav-wh {
  visibility: visible;
}
.address-filter-container {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #ffffff;
}
.geolocate-btn {
  border: none !important;
}
.address-selector-container {
  .v-list-item--link:before {
    background-color: #ffffff !important;
  }
  h3 p,
  .desc p {
    margin-bottom: 0 !important;
  }
  .tabs {
    .tab {
      border: 1px solid var(--v-grey-lighte3);
      &.selected {
        // color: $white !important;
        border-color: $primary;
      }
    }
    .v-icon {
      font-size: 60px;
      height: 80px;
    }
  }
  .address-list {
    overflow-y: auto;
    min-height: 200px;
    max-height: calc(100vh - 400px);

    .v-list-item {
      color: $text-color;
      border-bottom: 1px solid var(--v-grey-lighten2);
      min-height: 50px;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      max-height: calc(100vh - 225px);
      .v-list-item__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .v-list-item__title {
          align-self: flex-start;
        }
        .v-list-item__subtitle {
          white-space: normal;
        }
      }
    }
    .v-list-item__title,
    .v-list-item__subtitle {
      line-height: 18px !important;
    }
    .v-list-item--active {
      background: #ffffff;
      // color: $text-color;
      border-radius: $border-radius-root;
      .v-list-item__subtitle {
        color: $text-color;
      }
      .icon-edit {
        color: $text-color;
      }
    }
  }
  .v-text-field .v-input__slot {
    padding: 0 24px !important;
  }
}
</style>
<script>
// @ is an alias to /src
import StoreService from "~/service/storeService";
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";
//import TigrosCustomService from "@/service/tigrosCustomService";
// import CategoryBlock from "../categoryBlock/CategoryBlock.vue";
import { gmapApi } from "vue2-google-maps";

import { mapState, mapGetters } from "vuex";
import AddressClass from "../AddressClass.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import get from "lodash/get";
import AddressService from "~/service/addressService";
import userService from "@/commons/service/userService";
import gmapsInit from "@/components/buttons/gmaps";

var google;

export default {
  name: "AddressSelector",
  data() {
    return {
      store: {},
      addresses: [],
      loading: null,
      shippingAddress: null,
      deliveryService: null,
      tab: 1,
      searchText: null,
      category: {},
      eldersInfoRead: false,
      userData: null,
      googleEnabled: false,
      distanceRange: 10000,
      addressToSearch: null,
      showNearWarehouse: false
    };
  },
  components: { AddressClass, VueGoogleAutocomplete },
  computed: {
    google: gmapApi,
    ...mapGetters({
      user: "cart/getUser"
    }),
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    // eldersAddressEnabled() {
    //   let eldersLegalFound = false;
    //   for (let i = 0; i < this.user.legals.length; i++) {
    //     if ([2, 3].includes(this.user.legals[i].legalId)) {
    //       eldersLegalFound = true;
    //       break;
    //     }
    //   }
    //   return eldersLegalFound;
    // },
    // eldersAddress() {
    //   let found = this.filteredAddresses
    //     .map(function(address) {
    //       // Does current address has any type '1' class?
    //       let addressClasses = address.addressClass ? address.addressClass : [];
    //       let elderEnabled = addressClasses.reduce(function(
    //         total,
    //         currentValue
    //       ) {
    //         return total || currentValue["address-class-id"] === 1;
    //       },
    //       false);
    //       return {
    //         addressId: address.addressId,
    //         addressName: address.addressName,
    //         elderEnabled: elderEnabled
    //       };
    //     })
    //     .filter(function(address) {
    //       return address.elderEnabled;
    //     })
    //     .shift();

    //   return found ? found : null;
    // },
    deliveryServiceButtonsLength() {
      return this.deliveryServices.length == 1
        ? 4
        : 12 / this.deliveryServices.length;
    },
    filteredAddresses() {
      if (this.searchText) {
        return this.addresses.filter(address => {
          return (
            address.addressName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.address1
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.city.toLowerCase().indexOf(this.searchText.toLowerCase()) >
              -1
          );
        });
      } else {
        return this.addresses;
      }
    },
    ...mapState({
      cart: ({ cart }) => cart.cart,
      selectedService: ({ cart }) => cart.selectedService
    }),
    selectedDeliveryServiceId() {
      return this.deliveryService ? this.deliveryService.deliveryServiceId : 0;
    },
    deliveryServices() {
      if (this.store && this.store.deliveryServices) {
        return this.store.deliveryServices.filter(button => {
          return button.showTab;
        });
      }
      return [];
    }
  },
  methods: {
    async getUserData() {
      this.userData = await userService.getUserDetail();
    },
    async setFavWh(address) {
      this.userData.defaultStoreAddress = { addressId: address.addressId };
      await userService.updateUserDetail(this.userData);
    },
    resetNearWarehouse() {
      this.addressToSearch = null;
      this.fetchAddresses(this.deliveryService.deliveryServiceId);
    },
    async addressToSearchChanged(address) {
      this.addressToSearch = {
        latitude: address.latitude,
        longitude: address.longitude,
        distance: this.distanceRange
      };
      await this.findWarehouse();
    },
    // async distanceRangeChanged() {
    //   if (this.addressToSearch) {
    //     this.addressToSearch.distance = this.distanceRange;
    //     await this.findWarehouse();
    //   }
    // },
    geolocate() {
      let vm = this;
      if (navigator.geolocation) {
        document.getElementById("pacman-input").value = "";
        document.getElementById("pacman-input").placeholder = `${this.$t(
          "addressSelector.localizationLoading"
        )}`;

        navigator.geolocation.getCurrentPosition(
          function(position) {
            var latlng = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            let addressData = null;
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, function(results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                addressData = results[0];
                vm.addressToSearch = {
                  latitude: addressData.geometry.location.lat(),
                  longitude: addressData.geometry.location.lng(),
                  distance: vm.distanceRange
                };
                document.getElementById("pacman-input").placeholder = vm.$t(
                  "navbar.service." +
                    vm.selectedDeliveryServiceId +
                    ".searchLabel"
                );
                document.getElementById("pacman-input").value =
                  addressData.formatted_address;
                vm.showNearWarehouse = true;
                // vm.addressToSearch = {
                //   latitude: addressData.latitude,
                //   longitude: addressData.longitude,
                //   distance: vm.distanceRange
                // };
                vm.findWarehouse();
              } else {
                console.log(
                  "Geocode was not successful for the following reason: "
                );
              }
            });
          },
          function(err) {
            console.log(err);
            alert(this.$t("addressSelector.alert"));
          },
          { enableHighAccuracy: false, maximumAge: Infinity }
        );
      }
    },
    async findWarehouse() {
      let searchParams = {
        province_id: this.provinceId,
        parent_address_class_id: this.parentAddressClassId,
        region_id: this.regionId
      };
      if (this.addressToSearch) {
        searchParams["parent_latitude"] = this.addressToSearch.latitude;
        searchParams["parent_longitude"] = this.addressToSearch.longitude;
        searchParams["parent_distance"] = this.addressToSearch.distance;
      }
      let data = await AddressService.findWarehouse(searchParams);
      this.warehouseList = data.warehouses;
      this.addresses = data.warehouses
        .map(w => w.address)
        .filter(a => a.active == 1);
    },
    addressClass(address) {
      if (address && address.addressClass && address.addressClass.length) {
        return address.addressClass[0];
      }
    },
    filteredAddressClasses(addressClasses) {
      // show only addressClass "new" that is addressClassType: 2
      return addressClasses.filter(
        addressClass =>
          addressClass.addressClassGroup.addressClassGroupId == 2 &&
          addressClass.iconSource &&
          addressClass.iconSource != ""
      );
    },
    // async setAsEldersAddress(address) {
    //   // Try to select address class
    //   try {
    //     let res = await TigrosCustomService.selectAddressClass(
    //       address.addressId,
    //       1
    //     );
    //     if (res.response.status == 0) {
    //       this.loading = true;
    //       await this.$store.dispatch("cart/setAddress", {
    //         addressType: address.addressType,
    //         addressId: address.addressId
    //       });
    //     }
    //     this.fetchAddresses(this.selectedDeliveryServiceId);
    //   } catch (err) {
    //     console.log(err);
    //   } finally {
    //     this.loading = false;
    //   }
    //   // this.eldersAddress = deliveryController.warehouses[index];

    //   // .then(function() {
    //   // });
    // },
    async remove(address) {
      let _this = this;
      const res = await _this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        let response = await DeliveryService.removeAddress(address.addressId);
        _this.addresses = response.addresses;
      }
    },
    edit(address) {
      this.$router.push({
        name: "EditAddresses",
        params: { addressId: address.addressId }
      });
      this.$emit("submit", false);
    },
    async fetchStore() {
      let _this = this;
      let store = await StoreService.getStoreById(_this.cart.store.storeId);
      _this.store = store;

      _this.store.deliveryServices.find(deliveryService => {
        if (
          deliveryService.deliveryServiceId ==
          _this.cart.shippingAddress.deliveryServiceId
        ) {
          _this.deliveryService = deliveryService;
        }
      });
    },
    async fetchAddresses(deliveryService, idx) {
      let _this = this;
      if (typeof idx !== "undefined") {
        _this.tab = idx;
      }
      _this.loading = true;
      _this.searchText = null;
      let deliveryServiceId =
        deliveryService.deliveryServiceId || deliveryService;
      _this.deliveryService = deliveryService;
      _this.addresses = await StoreService.getAddressesByDeliveryService(
        deliveryServiceId
      );

      _this.addresses.find((address, index) => {
        if (address.addressId == _this.cart.shippingAddress.addressId) {
          _this.shippingAddress = index;
        }
      });

      if (_this.userData?.defaultStoreAddress?.addressId) {
        let index = _this.addresses.findIndex(
          wh => wh.addressId === this.userData.defaultStoreAddress.addressId
        );
        if (index !== -1 && index !== 0) {
          let favWh = _this.addresses.splice(index, 1)[0];
          _this.addresses.unshift(favWh);
        }
      }

      _this.loading = false;
    },
    async setAddress(address) {
      let _this = this;
      this.loading = true;
      let data = await this.$store.dispatch("cart/setAddress", {
        addressType: address.addressType,
        addressId: address.addressId
      });

      this.loading = false;
      if (data) {
        _this.$emit("submit", true);
        //Do not go home on address change
        // setTimeout(function() {
        //   _this.$router.push({ name: "Home" });
        // }, 200);
      }
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "service-selector"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  async created() {
    try {
      google = await gmapsInit(global.config.google_api_key);
      if (google) {
        this.googleEnabled = true;
      }
    } catch (err) {
      console.log(err);
    }
    await this.getUserData();
    await this.fetchStore();
    await this.fetchAddresses(this.cart.shippingAddress.deliveryServiceId);
    await this.fetchCategory();
    let dsid =
      this.deliveryService || this.deliveryServices[0].deliveryServiceId;

    const deliveryServiceObj = this.deliveryServices.filter(
      ds => ds.deliveryServiceId === dsid
    );
    const deliveryServiceIdx = this.deliveryServices
      .map(ds => ds.deliveryServiceId)
      .indexOf(dsid);
    await this.fetchAddresses(deliveryServiceObj[0], deliveryServiceIdx);
  }
};
</script>
