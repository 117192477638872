<template>
  <v-row no-gutters class="px-9">
    <v-col cols="12">
      <div class="warehouse-list">
        <div v-if="title && subtitle">
          <h2 class="primary--text">{{ title }}</h2>
          <h5>{{ subtitle }}</h5>
        </div>
        <div v-else>
          <h2 class="primary--text">{{ $t("warehouse.giftCards.title") }}</h2>
          <h5>{{ $t("warehouse.giftCards.subtitle") }}</h5>
        </div>

        <v-row no-gutters>
          <v-col
            class="my-4 py-md-3"
            order="2"
            order-sm="1"
            cols="12"
            :sm="showMap ? '5' : '12'"
            :xl="showMap ? '4' : '12'"
          >
            <v-list class="warehouse-list-list pt-0" :height="mapHeight">
              <v-list-item
                class="warehouse-list-list--item"
                v-for="(warehouse, index) in warehouseList"
                :key="`warehouse_${index}`"
                two-line
                dense
                :to="{ path: `/negozi-e-volantini/${warehouse.slug}` }"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="default--text text-capitalize text-left font-weight-medium mb-2"
                  >
                    {{ warehouse.address.addressName || warehouse.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="defaul--text warehouse-address">
                    {{ warehouse.address.address1 }},{{
                      warehouse.address.addressNumber
                    }}
                    {{ warehouse.address.city }} ({{
                      warehouse.address.province
                    }})
                    <div
                      v-if="
                        warehouse.metaData.warehouse_locator &&
                          warehouse.metaData.warehouse_locator.PHONE
                      "
                    >
                      Telefono: {{ warehouse.metaData.warehouse_locator.PHONE }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-icon class="font-weight-bold" color="primary"
                  >$arrowForward</v-icon
                >
              </v-list-item>
            </v-list>
          </v-col>
          <v-col
            class="py-0 py-md-3"
            order="1"
            order-sm="2"
            cols="12"
            :sm="showListParsed ? '7' : '0'"
            :xl="showListParsed ? '8' : '0'"
            v-if="showMap"
          >
            <GmapMap
              ref="mapRef"
              :center="{
                lat: addressToSearch ? addressToSearch.latitude : 45.67,
                lng: addressToSearch ? addressToSearch.longitude : 8.85
              }"
              :zoom="zoomLevel"
              :style="{ width: mapWidth, height: mapHeight }"
              :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: false
              }"
            >
              <GmapMarker
                :ref="'warehousMarker_' + warehouse.warehouseId"
                v-for="(warehouse, i) in warehouseList"
                :key="`warehouse_${i}`"
                :position="
                  google &&
                    new google.maps.LatLng(
                      warehouse.address.latitude
                        ? warehouse.address.latitude
                        : Math.random() + 45.67,
                      warehouse.address.longitude
                        ? warehouse.address.latitude
                        : Math.random() + 8.85
                    )
                "
                :clickable="true"
                icon="/img_layout/warehouse/pin.svg"
                @click="toggleInfoWindow(warehouse, i)"
              />
              <gmap-info-window
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick.stop="infoWinOpen = false"
              >
                {{ selectedWarehouse }}
                Contenuto
              </gmap-info-window>
              <gmap-circle
                v-if="addressToSearch"
                :center="{
                  lat: addressToSearch.latitude,
                  lng: addressToSearch.longitude
                }"
                :radius="addressToSearch.distance"
                :visible="true"
                :options="{ fillOpacity: 0.3, strokeWeight: 0.3 }"
              >
              </gmap-circle>
            </GmapMap>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<style lang="scss">
.warehouse-list-list {
  &--item {
    border-top: 1px solid var(--v-grey-lighten3);
    //border-bottom: 1px solid var(--v-grey-lighten3);
  }
  height: 380px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: initial;
  }
  overflow-y: auto;
  .warehouse-address {
    line-height: initial;
    font-weight: 400;
  }
  .v-list-item__title {
    text-transform: uppercase;
    color: var(--v-primary-base);
    font-size: 14px;
    font-weight: bold;
  }
  .v-list-item__subtitle {
    color: $text-color;
  }
  .v-avatar {
    padding: 2px;
    margin: 0;
    img {
      width: 24px;
      height: 34px;
    }
  }
}
.gm-style .gm-style-iw-c {
  height: 300px !important;
  width: 340px !important;
  min-height: 300px !important;
}

.gm-style-iw-chr {
  display: none !important;
}
.gm-style-iw-d {
  max-height: 296px !important;
}
.gm-style-iw.gm-style-iw-c {
  min-width: 260px !important;
}
#infoWindowDialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  cursor: pointer;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--v-primary-base);

  h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--v-primary-base);
  }
  .buongusto-infowindow-btn {
    color: var(--v-primary-base) !important;
  }
}
</style>
<script>
import categoryMixins from "~/mixins/category";
import AddressService from "~/service/addressService";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "WarehouseMap",
  mixins: [categoryMixins],
  props: {
    regionId: { type: String, required: false },
    provinceId: { type: String, required: false },
    parentAddressClassId: { type: String, required: false },
    showMap: { type: Boolean, default: false },
    config: { type: Object, required: true }
  },
  data() {
    return {
      showListParsed: true,
      warehouseFacets: null,
      warehouseList: [],
      warehouseListOriginal: [],
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      selectedWarehouse: null,
      selectedRegionId: null,
      addressToSearch: null,
      parentWarehouseClassId: null,
      title: null,
      subtitle: null
    };
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },

    mapHeight() {
      return this.$vuetify.breakpoint.xsOnly ? "300px" : "480px";
    },
    pageContent() {
      return this.page && this.page.content ? this.page.content.rendered : "";
    },
    pageTitle() {
      return this.page && this.page.title ? this.page.title.rendered : "";
    },
    zoomLevel() {
      if (this.addressToSearch) {
        return this.addressToSearch.distance == 10000 ? 12 : 10;
      } else {
        return this.$vuetify.breakpoint.xsOnly ? 8 : 9;
      }
    }
  },
  methods: {
    async findWarehouse() {
      let searchParams = {
        parent_warehouse_class_id: this.parentWarehouseClassId
      };
      let data = await AddressService.findWarehouse(searchParams);
      this.warehouseList = data.warehouses;
    },
    animateMarker(warehouse, status) {
      if (status) {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = this.google.maps.Animation.BOUNCE;
      } else {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = null;
      }
    },
    /**TODO: set logic for default store  */
    toggleInfoWindow(warehouse) {
      this.infoWindowPos = {
        lat: 46.06848178350493,
        lng: 11.123155131225657
      };

      let infoWindowBtn = null;
      infoWindowBtn = `
      <div class="d-flex flex-column justify-center align-center"><a href="/volantini/?leaflet_types=${
        warehouse.metaData?.warehouse_detail?.LEAFLET_TYPE
      }" class="d-flex align-center justify-center primary rounded-pill px-3 py-3 w-100 text-decoration-none router-link-active"><span class="text-uppercase white--text font-weight-bold">${this.$t(
        "warehouse.goToFlyer"
      )}</span></a><a href="/negozi-e-volantini/${
        warehouse.slug
      }" class="my-5 w-100 d-flex align-center justify-center text-center primary--text"><span class="text-uppercase primary--text">${this.$t(
        "warehouse.gotToWarehouseDetail"
      )}</span></a></div>`;

      let defaultStore = null;
      defaultStore = `<div class="mb-3 d-flex align-center"><i aria-hidden="true" class="mr-2 v-icon notranslate icon icon-star-full theme--light success--text" style="font-size: 16px;"></i><small class="success--text font-weight-bold text-uppercase">${this.$t(
        "warehouse.favouriteStore"
      )}</small></div>`;

      const serviceHours = warehouse.serviceHours;
      const beginTime =
        serviceHours.default.length > 0
          ? serviceHours.default[0].beginHour
          : serviceHours.custom.length > 0
          ? serviceHours.custom[0].beginHour
          : null;

      const endTime =
        serviceHours.default.length > 0
          ? serviceHours.default[0].endHour
          : serviceHours.custom.length > 0
          ? serviceHours.custom[0].endHour
          : null;

      const isStoreOpen = (() => {
        if (!beginTime || !endTime) return false;
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinutes = now.getMinutes();
        const currentTime = currentHour * 60 + currentMinutes;
        const [beginHour, beginMinute] = beginTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);
        const beginTimeMinutes = beginHour * 60 + beginMinute;
        const endTimeMinutes = endHour * 60 + endMinute;
        return currentTime >= beginTimeMinutes && currentTime <= endTimeMinutes;
      })();
      // eslint-disable-next-line no-unused-vars
      let isStoreOpenIndicator = null;
      if (isStoreOpen) {
        isStoreOpenIndicator = `<div class="mr-2 pa-1 rounded-pill success"></div>`;
      } else {
        isStoreOpenIndicator = `<div class="mr-2 pa-1 rounded-pill error"></div>`;
      }
      let serviceHoursSection = `<div
                  class="mb-3 pa-3 grey rounded-md d-flex align-center"
                >
                  <div class="w-100 d-flex align-center justify-space-between">
                    ${isStoreOpenIndicator}<span class="default--text font-weight-bold">${this.$t(
        "warehouse.today"
      )}</span></span>
                  <span class="ml-auto default--text font-weight-bold">${beginTime} - ${endTime}</span></div>
                </div>`;

      this.infoOptions.content = `<div id='infoWindowDialog' style='font-family: Rubik, arial, sans-serif'>
        <h3 class="mb-0 d-flex default--text align-center"> ${warehouse.address
          .addressName || warehouse.name} </h3>
            <small class="default--text mb-1">
        ${warehouse.address.address1} ${warehouse.address.addressNumber},
            ${warehouse.address.city} ( ${warehouse.address.province} ) ${
        warehouse.address.postalcode
      } 
      </small>
          ${defaultStore}
          ${serviceHoursSection}
          ${infoWindowBtn}
        </div>`;

      //check if its the same marker that was selected if yes toggle
      if (
        this.selectedWarehouse &&
        this.selectedWarehouse.warehouseId == warehouse.warehouseId
      ) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.selectedWarehouse = warehouse;
      }
    }
  },
  async mounted() {
    this.parentWarehouseClassId = this.$ebsn.meta(
      this.config,
      "categoryblocktype_WarehouseMap.PARENT_WAREHOUSE_CLASS_ID"
    );
    this.title = this.$ebsn.meta(
      this.config,
      "categoryblocktype_WarehouseMap.TITLE"
    );
    this.subtitle = this.$ebsn.meta(
      this.config,
      "categoryblocktype_WarehouseMap.SUBTITLE"
    );
    await this.findWarehouse();
  }
};
</script>
