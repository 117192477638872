<template>
  <div v-if="bannerImg" class="institutional-banner pa-0">
    <v-card
      :img="bannerImg.location"
      height="276"
      widht="100%"
      class="w-100 banner-img d-flex justify-start align-center"
    >
      <div class="banner-content px-6">
        <h2 class="institutional-banner-title mt-0">{{ title }}</h2>
        <h3 class="institutional-banner-subtitle mt-0">{{ subtitle }}</h3>
      </div>
    </v-card>
  </div>
</template>
<style>
.institutional-banner {
  position: relative;
  z-index: 2;
  isolation: isolate;
  .institutional-banner-title {
    font-size: 30px;
    color: #ffffff;
    font-weight: 500;
  }
  .institutional-banner-subtitle {
    font-size: 22px;
    color: #ffffff;
    font-weight: 400;
  }
  .banner-content {
    z-index: 5;
  }
  .banner-img:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgb(65, 69, 72, 0.5) 0%, #00000000 100%);
    z-index: 1; /* Layer the gradient above the image */
  }
}
</style>
<script>
export default {
  name: "InstitutionalBanner",
  data() {
    return {
      bannerImg: null,
      title: null,
      subtitle: null
    };
  },
  props: { config: { type: Object, required: true } },
  methods: {
    async reload() {
      this.bannerImg = this.$ebsn.meta(
        this.config,
        "categoryblocktype_InstitutionalBanner.BANNER_IMG"
      );

      this.title = this.$ebsn.meta(
        this.config,
        "categoryblocktype_InstitutionalBanner.BANNER_TITLE"
      );

      this.subtitle = this.$ebsn.meta(
        this.config,
        "categoryblocktype_InstitutionalBanner.BANNER_SUBTITLE"
      );
    }
  },
  created() {
    this.reload();
  }
};
</script>
