<template>
  <div class="timeslot-selector d-flex flex-column">
    <!-- <div class="d-flex justify-center">
     <img
        class="logo"
        :src="serviceImg"
        height="60"
        alt="Logo Coop Trentino"
      /> 
    </div> -->
    <ebsn-meta
      class="text-center secondary--text"
      :target="category"
      path="category_info.TITLE"
      :default-value="category.name"
      tag="h2"
    ></ebsn-meta>
    <ebsn-meta
      :target="category"
      path="category_info.DESCRIPTION"
      class="text-center text-body-2 mt-2"
    ></ebsn-meta>
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
      :container="false"
    />
    <v-tabs
      hide-slider
      v-model="tab"
      center-active
      show-arrows
      :height="120"
      class="days-tabs"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        v-for="timeslotDay in days"
        :key="timeslotDay.dateIso"
        class="days-tab rounded-md mr-3"
        @click="selectTimeslotDay(timeslotDay)"
      >
        <div
          class="time-div d-flex flex-column align-center justify-space-around"
        >
          <template v-if="$dayjs().isSame(timeslotDay.dateIso, 'day')">
            <span class="today text-body-1 font-weight-bold">
              {{ $t("timeslots.today") }}
            </span>
          </template>
          <template v-else>
            <span class="day-week text-body-2">
              {{ $dayjs(timeslotDay.dateIso).format("dddd") }}
            </span>
            <span class="py-3 day-month text-body-1 font-weight-bold">
              {{ $dayjs(timeslotDay.dateIso).format("DD") }}
            </span>
            <span class="day-month text-body-2">
              {{ $dayjs(timeslotDay.dateIso).format("MMMM") }}
            </span>
          </template>
        </div>
      </v-tab>
    </v-tabs>
    <v-row
      no-gutters
      align="center"
      justify="space-between"
      justify-sm="start"
      class="range-buttons mt-5 mb-3"
    >
      <v-col
        cols="auto"
        sm="auto"
        v-for="(range, index) in timeslotRange"
        :key="index"
        class="d-flex justify-center mr-0 mr-sm-2"
      >
        <v-btn
          depressed
          class="pa-3 rounded-md"
          :class="
            rangeTab === range.periodId
              ? 'primary white--text'
              : 'default--text text--darken-2 range-buttons-item'
          "
          :min-width="$vuetify.breakpoint.xs ? 'auto' : 120"
          :small="$vuetify.breakpoint.xs"
          data-test="range-btn"
          :value="range.periodId"
          min-height="38"
          @click="rangeTab = range.periodId"
        >
          <v-icon
            v-if="range.icon"
            class="mr-2 timeslot-period-icon"
            :color="rangeTab === range.periodId ? 'white' : 'default'"
            >{{ range.icon }}</v-icon
          >
          <span class="range-text font-weight-normal">{{
            $t(range.text)
          }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <!-- <v-divider class="my-4"></v-divider> -->

    <v-tabs-items v-model="tab">
      <v-tab-item
        class="timeslot-container"
        v-for="timeslotDay in days"
        :key="timeslotDay.dateIso"
      >
        <v-row
          v-if="
            timeslotDay &&
              timeslotDay.timeslots &&
              timeslotDay.timeslots.length > 0
          "
        >
          <v-col
            cols="6"
            sm="4"
            v-for="timeslot in timeslotDay.timeslots"
            :key="timeslot.timeslotId"
            ><!--<span class="expires-time">{{
              expires(timeslot.cutoffDate, timeslot.cutoffTime)
            }}</span>-->
            <div
              outlined
              class="timeslot-card rounded-pill"
              :disabled="disabled"
              :class="[
                timeslot.status,
                { selected: timeslot.selected },
                $ebsn.meta(
                  timeslot.deliveryFee,
                  'metaData.deliveryfee_Info.CSS_CLASS'
                )
              ]"
              v-on:click="selectTimeslot(timeslot)"
            >
              <span class="justify-start flex-nowrap pa-0">
                <div class="rounded-pill time-frame py-2 px-1">
                  {{ timeslot.beginTime }} - {{ timeslot.endTime }}
                </div>
                <div
                  class="time-price py-2 px-1"
                  v-if="
                    timeslot.deliveryFee && timeslot.deliveryFee.promoShortDescr
                  "
                >
                  {{ timeslot.deliveryFee.promoShortDescr }}
                </div>
              </span>
              <!-- <v-card-subtitle class="text-center">
                {{ $t(`timeslots.availability.${timeslot.status}`) }}
              </v-card-subtitle> -->
            </div>
          </v-col>
        </v-row>
        <p v-else>
          <v-alert type="error" outlined>{{ $t("timeslots.noSlots") }}</v-alert>
        </p>
      </v-tab-item>
    </v-tabs-items>
    <!-- <v-divider class="mt-4"></v-divider> -->
    <!-- <h6 class="text-uppercase">Legenda</h6> -->
    <v-row class="legend">
      <v-col cols="12" sm="3">
        <div class="d-flex align-center">
          <div class="mr-2 legend__shape legend__shape--free"></div>
          <span class="legend__text text-capitalize">
            {{ $t("timeslots.availability.empty") }}</span
          >
        </div>
      </v-col>

      <v-col cols="12" sm="3">
        <div class="d-flex align-center">
          <div class="mr-2 legend__shape legend__shape--intermediate"></div>
          <span class="legend__text text-capitalize">
            {{ $t("timeslots.availability.intermediate") }}</span
          >
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <div class="d-flex align-center">
          <div class="mr-2 legend__shape legend__shape--full"></div>
          <span class="legend__text text-capitalize">
            {{ $t("timeslots.availability.full") }}</span
          >
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <div class="d-flex align-center">
          <div class="mr-2 legend__shape legend__shape--selected"></div>
          <span class="legend__text text-capitalize">
            {{ $t("timeslots.availability.selected") }}</span
          >
        </div>
      </v-col>
    </v-row>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
      :container="false"
    />
  </div>
</template>
<style lang="scss">
.timeslot-selector {
  row-gap: 1.45rem;
  .range-buttons-item {
    border: 1px solid var(--v-grey-base) !important;
    border-color: var(--v-grey-base) !important;
  }
  h3 {
    text-align: center;
  }
  .days-tabs {
    .days-tab {
      min-width: 125px;
      min-height: 120px;
      border: 2px solid var(--v-grey-lighten2);
      //background-color: var(--v-grey-lighten2);
      border-radius: $border-radius-root;
      .time-div {
        color: var(--v-default-base);
      }
      &.v-tab--active {
        border: 2px solid var(--v-primary-base);
        background-color: var(--v-primary-base);
        .time-div {
          color: var(--v-white-base);
        }
      }
    }
  }
  .timeslot-card {
    padding: 10px 0;
    .v-card__title {
      padding: 6px;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 16px;
      }
    }
    &.selected {
      .v-card__title,
      .v-card__subtitle {
        color: var(--v-white-base);
      }
      background-color: var(--v-primary-base);
      .time-frame {
        color: var(--v-white-base);
        border: 1px solid var(--v-primary-base);
      }
      &.intermediate {
        .v-card__title .time-frame {
          color: var(--v-default-base);
        }
      }
    }
    &.empty {
      border-color: var(--v-grey-base);
    }
    &.intermediate {
      background-color: #fff4c8;
      border-color: var(--v-grey-base);
      .v-card__title,
      .v-card__subtitle {
        border-color: var(--v-accent-base);
      }
    }
    &.full {
      border-color: var(--v-grey-darken1);
      background-color: var(--v-grey-base);
      .v-card__title {
        color: var(--v-default-base);
      }
    }
    &.promo-yellow-azure {
      .time-price {
        background-color: var(--v-accent-base);
        color: var(--v-secondary-base);
      }
    }
    &.promo-only-azure {
      .time-price {
        margin: -1px;
        color: var(--v-secondary-base);
        border: 1px solid var(--v-secondary-base);
        border-top-right-radius: $border-radius-root;
        border-bottom-right-radius: $border-radius-root;
      }
    }
    &.promo-red-white {
      .time-price {
        background-color: var(--v-primary-base);
        color: var(--v-white-base);
      }
    }
    &.promo-red-white-promo {
      .time-price {
        background-color: var(--v-primary-base);
        color: var(--v-white-base);
        line-height: 1rem;
        white-space: initial;
        &::before {
          content: "PROMO";
          font-size: 11px;
          font-weight: normal;
        }
      }
    }
    .time-frame {
      font-size: 14px;
      //font-weight: 600;
      flex-grow: 1;
      text-align: center;
    }
    .time-price {
      text-align: center;
      border-left: 1px solid var(--v-grey-base);
      font-size: 16px;
      width: 40%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: nowrap;
    }
  }
  .expires-time {
    font-size: 10px;
    text-transform: uppercase;
    padding-left: 10px;
  }
  .legend {
    &__shape {
      width: 30px;
      height: 30px;
      &--free {
        border: 1px solid var(--v-grey-base);
      }
      &--intermediate {
        background-color: #fff89c;
      }
      &--full {
        background-color: #dadbdc;
      }
      &--selected {
        background-color: var(--v-primary-base);
      }
    }
    // .timeslot-card {
    //   .v-card__title {
    //     font-size: 14px;
    //   }
    // }
  }
}
</style>
<script>
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import { mapState } from "vuex";

import get from "lodash/get";
import cartInfo from "~/mixins/cartInfo";

export default {
  name: "TimeslotSelector",
  mixins: [cartInfo],
  data() {
    return {
      days: [],
      tab: 0,
      category: {},
      selectedTimeslotDay: {},
      disabled: false,
      rangeTab: [0],
      timeslotRange: {
        0: { periodId: [0], text: "timeslots.allTimeRanges" },
        1: { periodId: [1, 4], icon: "$morning", text: "timeslots.morning" },
        2: {
          periodId: [2, 3, 5, 6, 7, 8, 9],
          icon: "$afternoon",
          text: "timeslots.afternoon"
        }
      }
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    description() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    }
  },
  methods: {
    openAddressSelector() {
      this.$emit("submit", "setAddress");
    },
    selectTimeslotDay(day) {
      this.selectedTimeslotDay = day;
    },
    checkDeliveryDayPeriodId(item) {
      return (
        this.rangeTab == 0 || this.rangeTab.includes(item.deliveryDayPeriodId)
      );
    },
    async selectTimeslot(timeslot) {
      let _this = this;
      if (timeslot.active < 1) {
        return;
      }
      _this.disabled = true;
      let data = await _this.$store.dispatch("cart/setTimeslot", {
        dateIso: _this.selectedTimeslotDay.dateIso,
        timeslotId: timeslot.timeslotId
      });
      if (data) {
        this.$emit("submit", true);
      }
    },
    async fetchTimeslots() {
      let _this = this;
      _this.days = await DeliveryService.getTimeslotList(false);
      if (_this.days.length > 1 && _this.days[0].timeslots.length == 0) {
        _this.days.shift();
      }
      for (var i = 0; i < _this.days.length; i++) {
        if (_this.days[i].dateIso == _this.cart.timeslot.date) {
          _this.tab = i;
          this.selectedTimeslotDay = _this.days[i];
          break;
        }
      }
      if (!_this.selectedTimeslotDay.dateIso) {
        _this.selectTimeslotDay(_this.days[0]);
      }

      return false;
    },
    expires(day, time) {
      let today = this.$dayjs();
      let date = this.$dayjs(day + " " + time, "DD/MM/YYYY hh:mm");
      let diff =
        date.diff(today, "days") > 0
          ? date.diff(today, "days") + " giorni "
          : date.diff(today, "hours") > 0
          ? date.diff(today, "hours") + " ore "
          : date.diff(today, "minute") + " minuti";

      return "scade tra " + diff;
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug("timeslot");
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.disabled = false;
    this.fetchTimeslots();
    this.fetchCategory();
  }
};
</script>
