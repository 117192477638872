<template>
  <div
    class="row no-gutters pa-4 grey d-flex align-center w-100 google-autocomplete-input-container rounded-md"
  >
    <v-text-field
      id="cop-input"
      dense
      outlined
      clearable
      hide-details
      color="primary"
      background-color="white"
      v-model="query"
      :placeholder="$t('cooperatives.filter.search')"
      :label="$t('cooperatives.filter.search')"
      @input="handleSearch"
      @click:clear="resetNearWarehouse"
    >
    </v-text-field>

    <v-btn
      :loading="geoLoading"
      :ripple="false"
      class="geolocate-btn rounded-lg ml-2"
      color="primary"
      :min-width="$vuetify.breakpoint.lgAndUp ? 150 : 64"
      icon
      @click="geolocate()"
    >
      <v-icon class="font-weight-bold" color="primary">$locationPointer</v-icon>
      <span
        v-if="$vuetify.breakpoint.lgAndUp"
        class="primary--text text-decoration-underline font-weight-bold"
        >geolocation</span
      >
    </v-btn>
  </div>
</template>
<style lang="scss">
.warehouse-filter {
  .v-input__control .v-input__slot {
    border: 0 !important;
  }
  .v-input__slot {
    height: 44px !important;
  }

  .v-text-field--outlined fieldset {
    color: white !important;
    border-radius: 8px;
  }
}
#pac-input {
  background-color: #ffffff !important;
}
.google-autocomplete-input-container {
  display: flex;
  border: 1px solid var(--v-grey-base);
  padding: 3px 6px 3px 15px;
  background: #ffffff;
  font-size: 14px;
  .google-autocomplete-input {
    width: inherit;
    &:focus {
      outline: none;
    }
  }
}
</style>
<script>
export default {
  name: "CooperativesFilter",
  data() {
    return {
      geoLoading: false,
      query: null,
      distanceRange: 10000,
      addressToSearch: null,
      showNearWarehouse: false
    };
  },
  methods: {
    handleSearch() {
      if (this.query === "") {
        this.$emit("filterCooperatives", null);
      } else {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
          if (this.query?.length >= 4) {
            this.$emit("filterCooperatives", this.query);
          }
        }, 300);
      }
    },
    resetNearWarehouse() {
      this.showNearWarehouse = false;
      this.addressToSearch = null;
      document.getElementById("cop-input").value = "";
      this.$emit("resetNearWarehouse");
    },
    emitSearchAddress() {
      this.showNearWarehouse = true;
      this.$emit("addressToSearchChanged", this.addressToSearch);
    },
    distanceRangeChanged() {
      if (this.addressToSearch) {
        this.addressToSearch.distance = this.distanceRange;
        this.emitSearchAddress();
      }
    },
    addressToSearchChanged(address) {
      this.addressToSearch = {
        latitude: address.latitude,
        longitude: address.longitude,
        distance: this.distanceRange
      };
      this.emitSearchAddress();
    },
    geolocate() {
      let vm = this;
      vm.geoLoading = true;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            console.log(
              "bbb Latitude: " + latitude + ", Longitude: " + longitude
            );
            vm.addressToSearch = {
              latitude,
              longitude,
              distance: vm.distanceRange
            };
            vm.emitSearchAddress();
            vm.geoLoading = false;
          },
          function(error) {
            console.error("Error occurred. Error code: " + error.code);
            vm.geoLoading = false;
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    }
  }
};
</script>
