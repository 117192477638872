var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.category)?_c('div',{staticClass:"category-title container--fluid",class:{
    'w-100 d-flex flex-column justify-center text-center': _vm.centered,
    'd-flex flex-column flex-md-row': _vm.showHeaderImg,
    'd-none': _vm.$ebsn.meta(
      _vm.category,
      'metaData.category_info.HIDE_TITLE',
      false
    ),
    container: _vm.addContainer
  }},[_c('ebsn-style',{attrs:{"target":_vm.category,"path":"category_advanced.CSS_STYLE"}}),[(_vm.showHeaderImg && _vm.headerImage)?_c('v-img',{staticClass:"mr-sm-5",attrs:{"src":_vm.headerImage,"height":_vm.height,"max-width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '50%',"alt":_vm.category.name},on:{"load":function($event){_vm.loaded = true}}}):_vm._e(),_c('div',{staticClass:"d-flex align-center flex-row flex-wrap",class:{ 'justify-center': _vm.centered }},[(_vm.selectedCategory)?_c('v-btn',{attrs:{"icon":"","to":{ name: 'Category', params: { pathMatch: _vm.category.slug } },"exact":""}},[_c('v-icon',[_vm._v("$prev")])],1):_vm._e(),_c('ebsn-meta',{style:(`color: ${_vm.$ebsn.meta(_vm.category, 'category_info.COLOR')}`),attrs:{"target":_vm.selectedCategory ? _vm.selectedCategory : _vm.category,"path":"category_info.TITLE","tag":"h1","default-value":_vm.selectedCategory ? _vm.selectedCategory.name : _vm.category.name}}),(_vm.count > 0)?_c('v-spacer'):_vm._e(),(_vm.count > 0)?_c('strong',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.count)+" "+_vm._s(_vm.$t("navbar.products"))+" ")]):_vm._e()],1),_c('ebsn-meta',{staticClass:"description",attrs:{"target":_vm.category,"path":"category_info.DESCRIPTION","tag":"div"},on:{"click":_vm.clicked}})]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }