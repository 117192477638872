import OrderService from "~/service/orderService";

//const UPDATE_SITE = "updateSite";
const UPDATE_ORDER_TO_PAY = "updateOrderToPay";
const UPDATE_MENU_POSITION_MODEL = "updateMenuPositionModel";
const SET_APPLICATION_CONFIG = "setApplicationConfig";
const ADD_TO_LEAFLET_LIST = "addToLeafletList";
const REMOVE_FROM_LEAFLET_LIST = "removeFromLeafletList";
const EMPTY_LEAFLET_LIST = "emptyLeafletList";

const state = {
  // site: "ecommerce",
  orderToPay: [],
  leafletList: [],
  menuPositionModel: 0,
  applicationConfig: {}
};
const getters = {
  getProductsByLeafletId: state => leafletId => {
    const index = state.leafletList.findIndex(
      el => el.leaflet.leafletId == leafletId
    );
    if (index != -1) {
      return state.leafletList[0].products;
    } else return [];
  },
  getMenuPositionModel: state => {
    return state.menuPositionModel || 0;
  },
  getApplicationConfig: state => {
    return state.applicationConfig;
  }
  // getSite: state => {
  //   return state.site;
  // }
};

const actions = {
  [ADD_TO_LEAFLET_LIST]({ commit }, value) {
    commit(ADD_TO_LEAFLET_LIST, value);
  },
  [REMOVE_FROM_LEAFLET_LIST]({ commit }, value) {
    commit(REMOVE_FROM_LEAFLET_LIST, value);
  },
  [EMPTY_LEAFLET_LIST]({ commit }, value) {
    commit(EMPTY_LEAFLET_LIST, value);
  },
  async [UPDATE_ORDER_TO_PAY]({ commit }, value) {
    try {
      if (value) {
        commit(UPDATE_ORDER_TO_PAY, value);
        return true;
      } else {
        let orders = await OrderService.getOrdersRetryPayment();

        let ordersRetryPayment =
          orders.orders && orders.orders.length > 0
            ? orders.orders.map(order => order.orderId)
            : [];

        commit(UPDATE_ORDER_TO_PAY, ordersRetryPayment);
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  [UPDATE_MENU_POSITION_MODEL]({ commit }, value) {
    commit(UPDATE_MENU_POSITION_MODEL, value);
  },
  // [UPDATE_SITE]({ commit }, value) {
  //   commit(UPDATE_SITE, value);
  // },
  [SET_APPLICATION_CONFIG]({ commit }, value) {
    commit(SET_APPLICATION_CONFIG, value);
  }
};
const mutations = {
  [UPDATE_ORDER_TO_PAY]: (state, value) => {
    state.orderToPay = value;
  },
  [UPDATE_MENU_POSITION_MODEL]: (state, value) => {
    state.menuPositionModel = value;
  },
  // [UPDATE_SITE]: (state, value) => {
  //   state.site = value;
  // },
  [SET_APPLICATION_CONFIG]: (state, value) => {
    state.applicationConfig = value;
  },
  [ADD_TO_LEAFLET_LIST]: (state, { product, leaflet }) => {
    const index = state.leafletList.findIndex(
      el => el.leaflet.leafletId === leaflet.leafletId
    );
    if (index != -1) {
      const found = state.leafletList[index].products.find(
        el => el.productId === product.productId
      );
      if (!found) {
        state.leafletList[index].products.push(product);
        global.EventBus.$emit("success", {
          message: global.vm.$t("leaflet.list.productAddText")
        });
      }
    } else {
      state.leafletList.push({ leaflet: leaflet, products: [product] });
      global.EventBus.$emit("success", {
        message: global.vm.$t("leaflet.list.productAddText")
      });
    }
  },
  [REMOVE_FROM_LEAFLET_LIST]: (state, { productId, leafletId }) => {
    const index = state.leafletList.findIndex(
      el => el.leaflet.leafletId == leafletId
    );
    if (index != -1) {
      for (let i = 0; i < state.leafletList[0].products.length; i++) {
        if (state.leafletList[0].products[i].productId == productId) {
          state.leafletList[0].products.splice(i, 1);
          break;
        }
      }
    }
  },
  [EMPTY_LEAFLET_LIST]: (state, value) => {
    const index = state.leafletList.findIndex(
      el => el.leaflet.leafletId == value
    );
    if (index != -1) {
      state.leafletList.splice(index, 1);
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
