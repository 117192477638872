<template>
  <div class="institutional-card">
    <v-row class="align-center" :class="{ 'flex-row-reverse': reverse }">
      <v-col cols="12" md="6">
        <div class="img-wrapper pa-4">
          <img :src="imgsrc" :alt="title" class="card-img" />
        </div>
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-center">
        <div class="content-wrapper">
          <h3 class="title primary--text">{{ title }}</h3>
          <h4 class="subtitle">{{ subtitle }}</h4>
          <p v-if="description" class="description" v-html="description"></p>
          <v-btn
            v-if="buttonlink && buttonlabel"
            large
            depressed
            color="primary"
            class="rounded-pill mt-3 px-6"
            :link="buttonlink"
          >
            {{ buttonlabel }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped lang="scss">
.institutional-card {
  .card-img {
    max-width: 100%;
    border-radius: 30px;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
  }
  .subtitle {
    font-size: 26px;
    font-weight: 500;
  }
  .description {
    font-size: 18px;
  }
}
</style>
<script>
export default {
  name: "InstitutionalCard",
  props: {
    imgsrc: { type: String, required: true },
    title: { type: String, required: true },
    subtitle: { type: String, required: false },
    description: { type: String, required: false },
    buttonlabel: { type: String, required: false },
    buttonlink: { type: String, required: false },
    reverse: { type: Boolean, required: false, default: false }
  }
};
</script>
