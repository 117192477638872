<template>
  <v-card
    @click.prevent="
      mainTenantName == 'clickandcoop' ? goToProduct(product) : ''
    "
    outlined
    rounded="md"
    class="fill-height elevation-3 product-card product-card-leaflet"
    :class="[promoClass]"
    :id="`product-card-${product.slug}`"
  >
    <div class="product pt-2">
      <div class="d-flex justify-end align-center w-100 top mr-2">
        <v-tooltip left :key="heartKey" v-if="!coopProducts">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0 product-icon-button"
              icon
              v-bind="attrs"
              v-on="on"
              @click.stop.prevent="addToList({ leaflet, product })"
              @mousedown.stop
              color="primary"
              aria-label="Aggiungi alla lista della spesa"
            >
              <v-icon class="ml-auto" color="default">$list</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("product.addToList") }}</span>
        </v-tooltip>
      </div>
      <div class="w-100">
        <div class="d-flex flex-column body">
          <img
            :src="productImage"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="w-100 product-img align-self-center"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          />

          <div
            class="description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"
          >
            <span class="name font-weight-bold">
              {{ product.leafletName || product.name }}
            </span>
            <!-- <span class="cod-int text-caption d-none">
              cod. {{ product.codInt }}
            </span> -->
            <span class="short_descr">
              {{ product.leafletShortDescr || product.shortDescr }}
            </span>

            <div class="d-flex align-center">
              <div
                v-if="product.leafletDescr || product.description"
                class="descr"
              >
                <span> {{ product.leafletDescr || product.description }}</span>
              </div>
              <span> </span>
              <!-- {{ product.leafletDescr || product.description }} -->
              <!-- <span
                class="weight-unit"
                v-if="
                  product.productInfos &&
                    product.productInfos.TIPOLOGIA == 'Pezzo' &&
                    product.priceUmDisplay
                "
              >
                - {{ $n(product.priceUmDisplay, "currency") }}/{{
                  product.weightUnitDisplay
                }}
              </span> -->
              <span
                v-if="
                  product.productInfos?.TIPOLOGIA != 'Sfuso' &&
                    product.priceUmDisplay
                "
                class="weight-unit"
              >
                {{ $n(product.priceUmDisplay, "currency") }}/{{
                  product.weightUnitDisplay
                }}
              </span>
              <span
                v-else-if="product.productInfos?.TIPOLOGIA == 'Sfuso'"
                class="weight-unit descr"
              >
                <!-- <span v-else class="weight-unit descr"> -->
                {{ minQuantity }} {{ product.leafletUM || unit }}
              </span>

              <span v-if="leaflet"
                >{{
                  $n(product.leafletUnitPrice, "currency") ||
                    $n(product.priceDisplay, "currency")
                }}
                {{ product.leafletUM }}</span
              >
            </div>
          </div>
        </div>
        <div
          v-if="product.priceDisplay && !coopProducts"
          class="actions leaflet-price"
          @click.stop.prevent
        >
          <ProductPrice :product="product" :leafletProduct="true" />
        </div>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.product-card-leaflet {
  width: 100%;
  //max-width: 270px;
  min-height: 375px;
  .description {
    height: unset;
  }
  .product-card .actions {
    padding: 0;
  }
  .product-img {
    object-fit: contain;
    width: 100%;
    max-width: 170px;
    height: 170px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: 0px 17px;
      width: 100px;
      height: 100px;
    }
  }
  .product .description .name {
    font-family: $heading-font-family;
  }
  .promo-wrapper {
    position: absolute;
    top: -1px;
    left: -1px;
    display: flex;
    flex-direction: column;
    z-index: 3;
  }
  .cart-item-info {
    margin-bottom: -21px;
  }
  .product-icon-button {
    position: relative;
  }
  .top {
    position: absolute;
    height: 26px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-price {
      width: 140px;
      text-align: center;
    }
  }
  .leaflet-price {
    padding: 0 !important;
    .product-price {
      width: 100%;
      .promo_header {
        width: 50%;
      }
      .promo_body {
        width: 50%;
      }
    }
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";

import productMixin from "~/mixins/product";
import cartService from "@/commons/service/cartService";
import { mapState, mapGetters, mapActions } from "vuex";

import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ProductCard",
  props: {
    coopProducts: { type: Boolean, required: false },
    product: { type: Object, required: true },
    position: { type: Number, default: undefined },
    leaflet: { type: Object, required: false }
  },
  components: {
    ProductPrice
  },
  mixins: [productMixin],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      key: 0
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    productImage() {
      return this.coopProducts
        ? this.product.mediaURL
        : this.product.leafletImageUrl
        ? this.product.leafletImageUrl
        : this.product.mediaURL;
    },

    minQuantity() {
      return cartService.plus(this.product);
    },
    promoProductClasses() {
      return this.product.productClasses.filter(
        pClass => pClass.productClassGroupId === 6
      );
    },
    item() {
      this.key;
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    },
    promoClass() {
      let productClasses = [];
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.product.isNew) {
        productClasses.push("new-product");
      }
      if (this.product.badge) {
        productClasses.push(this.product.badge.cssClass);
      }
      return productClasses;
    }
  },
  methods: {
    ...mapActions({ addToList: "custom/addToLeafletList" }),
    giftAdded() {
      this.plus();
    },

    goToProduct(product) {
      this.$router.push({
        name: "Product",
        params: { slug: product.slug }
      });
    },
    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.value;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    handleClick() {
      this.$emit("selectProduct");
    }
  },
  mounted() {
    if (this.product.selectOptions && this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
    }
  }
};
</script>
