<template>
  <div class="institutional-card">
    <ebsn-meta
      :target="config"
      path="categoryblocktype_BannerGrid.TITLE"
      tag="h2"
    />
    <ebsn-meta
      :target="config"
      path="categoryblocktype_BannerGrid.DESCRIPTION"
      tag="div"
    />
    <v-row class="align-center" :class="{ 'flex-row-reverse': reverse }">
      <v-col cols="12" md="6">
        <div class="img-wrapper pa-4">
          <img :src="imgSrc" :alt="title" class="card-img" />
        </div>
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-center">
        <div class="content-wrapper">
          <h3 class="title primary--text">{{ title }}</h3>
          <h4 class="subtitle">{{ subtitle }}</h4>
          <p v-if="description" class="description" v-html="description"></p>
          <v-btn
            large
            depressed
            color="primary"
            class="rounded-pill mt-3 px-6"
            :link="buttonLink"
          >
            {{ buttonLabel }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped lang="scss">
.institutional-card {
  .card-img {
    max-width: 100%;
    border-radius: 30px;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
  }
  .subtitle {
    font-size: 26px;
    font-weight: 500;
  }
  .description {
    font-size: 18px;
  }
}
</style>
<script>
export default {
  name: "InstitutionalCard",
  data() {
    return {
      title: null,
      subtitle: null,
      description: null,
      imgSrc: null,
      buttonLabel: null,
      buttonLink: null,
      reverse: false
    };
  },
  props: { config: { type: Object, required: true } },
  created() {
    this.title = this.$ebsn.meta(
      this.config,
      "categoryblocktype_InstitutionalCard.CARD_TITLE"
    );
    this.subtitle = this.$ebsn.meta(
      this.config,
      "categoryblocktype_InstitutionalCard.CARD_SUBTITLE"
    );
    this.description = this.$ebsn.meta(
      this.config,
      "categoryblocktype_InstitutionalCard.CARD_DESCRIPTION",
      null
    );
    this.buttonLabel = this.$ebsn.meta(
      this.config,
      "categoryblocktype_InstitutionalCard.CARD_BTN_TEXT"
    );
    this.buttonLink = this.$ebsn.meta(
      this.config,
      "categoryblocktype_InstitutionalCard.CARD_BTN_LINK"
    );
    this.imgSrc = this.$ebsn.meta(
      this.config,
      "categoryblocktype_InstitutionalCard.CARD_IMG.location"
    );

    this.reverse = this.$ebsn.meta(
      this.config,
      "categoryblocktype_InstitutionalCard.REVERSE"
    );
  }
};
</script>
