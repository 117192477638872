import base from "./base";
// import wordpress from "./wordpress";
import warehouse from "./warehouse";
import catalog from "./catalog";
import profile from "./profile";
import checkout from "./checkout";
import cms from "./cms";

// import store from "./store";
const tenantName = process.env.VUE_APP_TENANT;

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      site: "sait",
      slug: ["homepage"]
    },
    props: true,
    component: () => import("@/views/HomeSite.vue")
  },
  {
    path: "/",
    name: "Home",
    props: true,
    meta: {
      site: "clickandcoop",
      //slug: ["click-and-coop"]
      slug: ["homepage-clickandcoop"]
    },
    component: () => import("@/views/Home.vue")
  },
  ...base,
  // ...wordpress,
  ...profile,
  ...checkout,
  ...warehouse,
  ...cms,
  // ...store,
  ...catalog
];
const filteredRoutes = routes.filter(route => {
  return (
    !route.meta?.site ||
    route.meta.site === tenantName ||
    route?.children?.filter(r => {
      return !r.meta?.site || r.meta.site;
    })
  );
});

console.log("f-r", filteredRoutes);

export default filteredRoutes;
