<template>
  <img
    v-if="addressClass"
    class="address-class store-logo"
    :src="src"
    height="26"
    contain
    :alt="`logo ${addressClass.name}`"
    :title="`${addressClass.name}`"
  />
</template>
<style lang="scss">
.address-class.store-logo {
  max-height: 26px;
  padding: 0 2px;
}
</style>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AddressClass",
  props: { addressClass: { type: Object, required: true } },
  computed: {
    ...mapGetters({
      language: "app/getLanguage"
    }),
    src() {
      return `/img_layout/warehouse/logo-${this.language}/${this.addressClass.iconSource}.png`;
    }
  }
};
</script>
