<template>
  <div>
    <div
      class="title-row mb-3"
      v-if="config.metaData.categoryblocktype_LeafletProductSlider.DESCRIPTION"
      v-html="
        config.metaData.categoryblocktype_LeafletProductSlider.DESCRIPTION
      "
    ></div>
    <div
      v-if="products && products.length > 0"
      :id="`product-list-slider-${config.categoryBlockId}`"
      class="product-list-slider"
      :class="classes"
      @mouseover="animateLink = true"
      @mouseleave="animateLink = false"
      v-intersect.once="handleView"
    >
      <TitleRow
        v-if="
          $ebsn.meta(
            config,
            'categoryblocktype_' + categoryBlockName + '.TITLE'
          )
        "
        :config="config"
        :categoryBlockName="categoryBlockName"
        :showMore="showMore"
        :animateLink="animateLink"
      />

      <ebsn-meta
        :target="config"
        :path="`categoryblocktype_${name}.DESCRIPTION`"
        tag="div"
        :class="'title-row mb-3'"
      />
      <div class="slider product-slider">
        <swiper :options="swiperOption" ref="swiperRef">
          <swiper-slide v-if="showCard">
            <v-card
              outlined
              :link="{
                name: 'Leaflet',
                params: {
                  leafletId: leaflet.slug
                    ? leaflet.slug
                    : leaflet.leafletId.toString()
                }
              }"
              class="pa-5 first-leaflet-slide d-flex flex-column align-end justify-center"
              height="100%"
              width="280"
            >
              <v-img :src="`${leaflet.baseLocation}/0.png`"></v-img>
              <v-card-actions class="mt-auto px-0 pt-3 pb-0 w-100">
                <v-btn
                  depressed
                  block
                  large
                  color="primary"
                  class="rounded-pill px-4 w-100"
                  :to="{
                    name: 'Leaflet',
                    params: {
                      leafletId: leaflet.slug
                        ? leaflet.slug
                        : leaflet.leafletId.toString()
                    }
                  }"
                >
                  {{ $t("leaflet.goToLeaflet") }}
                </v-btn>
              </v-card-actions>
            </v-card>
            <!-- <product-slider-card
            :alt="$t('leaflet.discoverExt')"
            :img="`${leaflet.baseLocation}/0.png`"
            :text="$t('leaflet.discoverExt')"
            :link="{
              name: 'Leaflet',
              params: {
                leafletId: leaflet.slug
                  ? leaflet.slug
                  : leaflet.leafletId.toString()
              }
            }"
            :linkLabel="$t('leaflet.discoverExt')"
          /> -->
          </swiper-slide>
          <swiper-slide
            v-for="(product, index) in products"
            :key="product.productId"
          >
            <!-- <ProductCard
            :key="product.productId"
            v-bind:product="product"
            :position="initPosition + index"
            @selectProduct="
              $emit('selectProduct', {
                product: product,
                index: initPosition + index
              })
            "
          /> -->
            <ProductCardLeaflet
              :key="product.productId"
              v-bind:product="product"
              :leaflet="leaflet"
              :position="initPosition + index"
              @selectProduct="
                $emit('selectProduct', {
                  product: product,
                  index: initPosition + index
                })
              "
            />
          </swiper-slide>
        </swiper>
        <swiper-paginator
          :showBullets="
            $ebsn.meta(
              config,
              'categoryblocktype_' + name + '.SHOW_BULLETS',
              true
            )
          "
          :showArrows="
            $ebsn.meta(
              config,
              'categoryblocktype_' + name + '.SHOW_ARROWS',
              false
            )
          "
          :paginationClass="config.categoryBlockId"
          :length="products?.length"
        />
      </div>
    </div>
  </div>
</template>
<style global lang="scss">
.ecommerce .product-list-slider .product-slider-card {
  height: 100%;
  border: 0;
}
.product-list-slider {
  .first-leaflet-slide {
    background-size: contain !important;
    background-position: top center !important;
  }
  .product-slider-card {
    width: 100%;
    height: $default-card-height;
    border: thin solid rgba(0, 0, 0, 0.12);
  }
  .swiper-slide {
    width: 280px !important;
    height: auto;
  }
  // @media #{map-get($display-breakpoints, 'xs-only')} {
  //   .swiper-button-prev,
  //   .swiper-button-next {
  //     display: none;
  //   }
  // }
  // @media #{map-get($display-breakpoints, 'sm-and-up')} {
  //   .swiper-pagination {
  //     display: none;
  //   }
  // }
}
</style>
<script>
// import ProductCard from "@/components/product/ProductCard.vue";
import ProductCardLeaflet from "@/components/leaflet/ProductCardLeaflet.vue";
// import ProductSliderCard from "./ProductSliderCard.vue";
import TitleRow from "./TitleRow.vue";

import categoryBlockType from "@/components/categoryBlock/categoryBlockType";
import analyticsService from "~/service/analyticsService";
import leafletService from "~/service/leafletService";

import Vue from "vue";
import SwiperPaginator from "@/components/SwiperPaginator.vue";

export default {
  name: "LeafletProductSlider",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true },
    categoryBlockName: { type: String, required: true },
    showMore: { type: Boolean, default: false },
    initPosition: { type: Number, default: 0 }
  },
  components: {
    //ProductCard,
    ProductCardLeaflet,
    // ProductSliderCard,
    TitleRow,
    SwiperPaginator
  },
  mixins: [categoryBlockType],
  data() {
    return {
      name: this.categoryBlockName,
      animateLink: false,
      swiperDefaultOption: {
        slidesPerGroup: 1,
        // slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        autoplay: false,
        pagination: {
          el: `#pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#prev-${this.config.categoryBlockId}`,
          nextEl: `#next-${this.config.categoryBlockId}`
        }
      },
      leafletId: null,
      leaflet: null,
      imgUrl: null,
      products: null
    };
  },
  computed: {
    showCard() {
      return Vue.$ebsn.meta(
        this.config,
        `categoryblocktype_LeafletProductSlider.SHOW_CARD`,
        false
      );
    },
    hasFirstCardSlot() {
      return !!this.$slots.firstCard;
    },
    classes() {
      return [
        "product-list-slider",
        Vue.$ebsn.meta(
          this.config,
          `metaData.categoryblocktype_${this.name}.CARD_MODE`,
          "vertical"
        ),
        this.showCard ? "has-card" : ""
      ];
    }
  },
  methods: {
    handleView(entries, observer, isIntersecting) {
      this.$emit("updateSliderWrapperViewable");
      if (isIntersecting && this.proposal?.length > 0) {
        analyticsService.viewPromotions(
          [this.proposal],
          this.position,
          this.products
        );
      }
    },
    handleClick() {},
    async fetchProducts(pageNumber = 0, limit = 10) {
      console.log(pageNumber);
      let response = await leafletService.searchProducts({
        parent_leaflet_id: this.leafletId,
        page_size: limit
        // parent_leaflet_page_number_id: pageNumber
      });
      this.products = response.data.products;
    },
    async fetchLeaflet(leafletId) {
      if (leafletId) {
        this.leaflet = await leafletService.getLeafletById(leafletId);
        await this.fetchProducts();
      }
    }
  },
  mounted() {
    this.leafletId = this.$ebsn.meta(
      this.config,
      "categoryblocktype_LeafletProductSlider.LEAFLET_ID"
    );
    this.fetchLeaflet(this.leafletId);
  }
};
</script>
