<template>
  <div
    v-if="products.length > 0"
    :id="`product-list-slider-${config.categoryBlockId}`"
    class="product-list-slider"
    :class="classes"
    @mouseover="animateLink = true"
    @mouseleave="animateLink = false"
    v-intersect.once="handleView"
  >
    <TitleRow
      v-if="
        $ebsn.meta(config, 'categoryblocktype_' + categoryBlockName + '.TITLE')
      "
      :config="config"
      :categoryBlockName="categoryBlockName"
      :showMore="showMore"
      :animateLink="animateLink"
    />

    <ebsn-meta
      :target="config"
      :path="`categoryblocktype_${name}.DESCRIPTION`"
      tag="div"
      :class="'title-row mb-3'"
    />
    <div class="slider product-slider">
      <swiper :options="swiperOption" ref="swiperRef">
        <swiper-slide v-if="showCard">
          <product-slider-card
            alt=""
            :img="
              $ebsn.meta(
                config,
                'categoryblocktype_' + name + '.CARD_IMAGE.location'
              )
            "
            :text="
              $ebsn.meta(config, 'categoryblocktype_' + name + '.CARD_TEXT')
            "
            :link="{
              name: 'Category',
              params: {
                pathMatch: $ebsn.meta(
                  this.config,
                  'categoryblocktype_' + name + '.CATEGORY.slug'
                )
              }
            }"
            :linkLabel="
              $ebsn.meta(config, 'categoryblocktype_' + name + '.BTN_TEXT')
            "
          />
        </swiper-slide>
        <swiper-slide
          v-for="(product, index) in products"
          :key="product.productId"
        >
          <ProductCard
            :key="product.productId"
            v-bind:product="product"
            :position="initPosition + index"
            @selectProduct="
              $emit('selectProduct', {
                product: product,
                index: initPosition + index
              })
            "
          />
        </swiper-slide>
      </swiper>
      <swiper-paginator
        :showBullets="
          $ebsn.meta(
            config,
            'categoryblocktype_' + name + '.SHOW_BULLETS',
            true
          )
        "
        :showArrows="
          $ebsn.meta(
            config,
            'categoryblocktype_' + name + '.SHOW_ARROWS',
            false
          )
        "
        :paginationClass="config.categoryBlockId"
        :length="products.length"
      />
    </div>
  </div>
</template>
<style global lang="scss">
.ecommerce .product-list-slider .product-slider-card {
  height: 100%;
  border: 0;
}
.product-list-slider {
  .product-slider-card {
    width: 100%;
    //  height: $default-card-height;
    border: thin solid rgba(0, 0, 0, 0.12);
  }
  .swiper-slide {
    width: auto;
  }
  // @media #{map-get($display-breakpoints, 'xs-only')} {
  //   .swiper-button-prev,
  //   .swiper-button-next {
  //     display: none;
  //   }
  // }
  // @media #{map-get($display-breakpoints, 'sm-and-up')} {
  //   .swiper-pagination {
  //     display: none;
  //   }
  // }
}
</style>
<script>
import ProductCard from "@/components/product/ProductCard.vue";
import ProductSliderCard from "./ProductSliderCard.vue";
import TitleRow from "./TitleRow.vue";

import categoryBlockType from "@/components/categoryBlock/categoryBlockType";
import analyticsService from "~/service/analyticsService";

import Vue from "vue";
import SwiperPaginator from "@/components/SwiperPaginator.vue";

export default {
  name: "ProductListSlider",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true },
    products: { type: Array, default: () => [] },
    categoryBlockName: { type: String, required: true },
    showMore: { type: Boolean, default: false },
    initPosition: { type: Number, default: 0 }
  },
  components: { ProductCard, ProductSliderCard, TitleRow, SwiperPaginator },
  mixins: [categoryBlockType],
  data() {
    return {
      name: this.categoryBlockName,
      animateLink: false,
      swiperDefaultOption: {
        slidesPerGroup: 1,
        // slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        autoplay: false,
        pagination: {
          el: `#pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#prev-${this.config.categoryBlockId}`,
          nextEl: `#next-${this.config.categoryBlockId}`
        }
      }
    };
  },
  computed: {
    showCard() {
      return Vue.$ebsn.meta(
        this.config,
        `categoryblocktype_${this.name}.SHOW_CARD`,
        false
      );
    },
    hasFirstCardSlot() {
      return !!this.$slots.firstCard;
    },
    classes() {
      return [
        "product-list-slider",
        Vue.$ebsn.meta(
          this.config,
          `metaData.categoryblocktype_${this.name}.CARD_MODE`,
          "vertical"
        ),
        this.showCard ? "has-card" : ""
      ];
    }
  },
  methods: {
    handleView(entries, observer, isIntersecting) {
      this.$emit("updateSliderWrapperViewable");
      if (isIntersecting && this.proposal?.length > 0) {
        analyticsService.viewPromotions(
          [this.proposal],
          this.position,
          this.products
        );
      }
    },
    handleClick() {}
  }
};
</script>
