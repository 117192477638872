var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.config.metaData.categoryblocktype_LeafletProductSlider.DESCRIPTION)?_c('div',{staticClass:"title-row mb-3",domProps:{"innerHTML":_vm._s(
      _vm.config.metaData.categoryblocktype_LeafletProductSlider.DESCRIPTION
    )}}):_vm._e(),(_vm.products && _vm.products.length > 0)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.handleView),expression:"handleView",modifiers:{"once":true}}],staticClass:"product-list-slider",class:_vm.classes,attrs:{"id":`product-list-slider-${_vm.config.categoryBlockId}`},on:{"mouseover":function($event){_vm.animateLink = true},"mouseleave":function($event){_vm.animateLink = false}}},[(
        _vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_' + _vm.categoryBlockName + '.TITLE'
        )
      )?_c('TitleRow',{attrs:{"config":_vm.config,"categoryBlockName":_vm.categoryBlockName,"showMore":_vm.showMore,"animateLink":_vm.animateLink}}):_vm._e(),_c('ebsn-meta',{class:'title-row mb-3',attrs:{"target":_vm.config,"path":`categoryblocktype_${_vm.name}.DESCRIPTION`,"tag":"div"}}),_c('div',{staticClass:"slider product-slider"},[_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},[(_vm.showCard)?_c('swiper-slide',[_c('v-card',{staticClass:"pa-5 first-leaflet-slide d-flex flex-column align-end justify-center",attrs:{"outlined":"","link":{
              name: 'Leaflet',
              params: {
                leafletId: _vm.leaflet.slug
                  ? _vm.leaflet.slug
                  : _vm.leaflet.leafletId.toString()
              }
            },"height":"100%","width":"280"}},[_c('v-img',{attrs:{"src":`${_vm.leaflet.baseLocation}/0.png`}}),_c('v-card-actions',{staticClass:"mt-auto px-0 pt-3 pb-0 w-100"},[_c('v-btn',{staticClass:"rounded-pill px-4 w-100",attrs:{"depressed":"","block":"","large":"","color":"primary","to":{
                  name: 'Leaflet',
                  params: {
                    leafletId: _vm.leaflet.slug
                      ? _vm.leaflet.slug
                      : _vm.leaflet.leafletId.toString()
                  }
                }}},[_vm._v(" "+_vm._s(_vm.$t("leaflet.goToLeaflet"))+" ")])],1)],1)],1):_vm._e(),_vm._l((_vm.products),function(product,index){return _c('swiper-slide',{key:product.productId},[_c('ProductCardLeaflet',{key:product.productId,attrs:{"product":product,"leaflet":_vm.leaflet,"position":_vm.initPosition + index},on:{"selectProduct":function($event){return _vm.$emit('selectProduct', {
                product: product,
                index: _vm.initPosition + index
              })}}})],1)})],2),_c('swiper-paginator',{attrs:{"showBullets":_vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_' + _vm.name + '.SHOW_BULLETS',
            true
          ),"showArrows":_vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_' + _vm.name + '.SHOW_ARROWS',
            false
          ),"paginationClass":_vm.config.categoryBlockId,"length":_vm.products?.length}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }