var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"px-9",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"warehouse-list"},[(_vm.title && _vm.subtitle)?_c('div',[_c('h2',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.title))]),_c('h5',[_vm._v(_vm._s(_vm.subtitle))])]):_c('div',[_c('h2',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$t("warehouse.giftCards.title")))]),_c('h5',[_vm._v(_vm._s(_vm.$t("warehouse.giftCards.subtitle")))])]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-4 py-md-3",attrs:{"order":"2","order-sm":"1","cols":"12","sm":_vm.showMap ? '5' : '12',"xl":_vm.showMap ? '4' : '12'}},[_c('v-list',{staticClass:"warehouse-list-list pt-0",attrs:{"height":_vm.mapHeight}},_vm._l((_vm.warehouseList),function(warehouse,index){return _c('v-list-item',{key:`warehouse_${index}`,staticClass:"warehouse-list-list--item",attrs:{"two-line":"","dense":"","to":{ path: `/negozi-e-volantini/${warehouse.slug}` }}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"default--text text-capitalize text-left font-weight-medium mb-2"},[_vm._v(" "+_vm._s(warehouse.address.addressName || warehouse.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"defaul--text warehouse-address"},[_vm._v(" "+_vm._s(warehouse.address.address1)+","+_vm._s(warehouse.address.addressNumber)+" "+_vm._s(warehouse.address.city)+" ("+_vm._s(warehouse.address.province)+") "),(
                      warehouse.metaData.warehouse_locator &&
                        warehouse.metaData.warehouse_locator.PHONE
                    )?_c('div',[_vm._v(" Telefono: "+_vm._s(warehouse.metaData.warehouse_locator.PHONE)+" ")]):_vm._e()])],1),_c('v-icon',{staticClass:"font-weight-bold",attrs:{"color":"primary"}},[_vm._v("$arrowForward")])],1)}),1)],1),(_vm.showMap)?_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"1","order-sm":"2","cols":"12","sm":_vm.showListParsed ? '7' : '0',"xl":_vm.showListParsed ? '8' : '0'}},[_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth, height: _vm.mapHeight }),attrs:{"center":{
              lat: _vm.addressToSearch ? _vm.addressToSearch.latitude : 45.67,
              lng: _vm.addressToSearch ? _vm.addressToSearch.longitude : 8.85
            },"zoom":_vm.zoomLevel,"options":{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUI: false
            }}},[_vm._l((_vm.warehouseList),function(warehouse,i){return _c('GmapMarker',{key:`warehouse_${i}`,ref:'warehousMarker_' + warehouse.warehouseId,refInFor:true,attrs:{"position":_vm.google &&
                  new _vm.google.maps.LatLng(
                    warehouse.address.latitude
                      ? warehouse.address.latitude
                      : Math.random() + 45.67,
                    warehouse.address.longitude
                      ? warehouse.address.latitude
                      : Math.random() + 8.85
                  ),"clickable":true,"icon":"/img_layout/warehouse/pin.svg"},on:{"click":function($event){return _vm.toggleInfoWindow(warehouse, i)}}})}),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){$event.stopPropagation();_vm.infoWinOpen = false}}},[_vm._v(" "+_vm._s(_vm.selectedWarehouse)+" Contenuto ")]),(_vm.addressToSearch)?_c('gmap-circle',{attrs:{"center":{
                lat: _vm.addressToSearch.latitude,
                lng: _vm.addressToSearch.longitude
              },"radius":_vm.addressToSearch.distance,"visible":true,"options":{ fillOpacity: 0.3, strokeWeight: 0.3 }}}):_vm._e()],2)],1):_vm._e()],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }