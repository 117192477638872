<template>
  <v-card
    class="product-slider-card fill-height"
    rounded="sm"
    elevation="0"
    flat
    :to="link"
    @click="$emit('selectProduct')"
  >
    <v-img
      :src="img"
      alt="Immagine categoria"
      class="product-slider-card-image"
    />

    <v-card-text v-if="text" v-html="text"> </v-card-text>
    <!-- <v-spacer></v-spacer> -->
    <v-card-actions v-if="link" class="product-slider-card-cta-wrapper">
      <v-btn
        large
        :to="link"
        class="rounded-pill product-slider-card-button"
        block
        depressed
        >{{ linkLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.product-slider-card {
  display: flex;
  flex-direction: column;
  &-cta-wrapper {
    background-color: var(--v-grey-base);
  }
  &-button {
    background-color: #ffffff !important;
    border: 1px solid var(--v-secondary-base);
    color: var(--v-secondary-base);
  }
}
</style>
<script>
export default {
  name: "ProductSliderCard",
  props: {
    img: { type: String, required: false },
    text: { type: String, required: false },
    link: { type: [String, Object], required: false },
    linkLabel: { type: String, required: false, default: "Mostra tutti" }
  }
};
</script>
