<template>
  <div class="blog-list wp-page">
    <Breadcrumb :items="breadcrumb" />
    <v-container class="article-list">
      <CategoryTitle
        v-if="category"
        :category="category"
        :showDescription="true"
      />
      <v-row>
        <v-col cols="12" sm="4" md="4" lg="3" xl="2" v-if="magazines">
          <v-card
            height="420"
            width="auto"
            outlined
            class="magazine-card align-start rounded-lg"
          >
            <v-img
              cover
              eager
              max-height="420"
              :src="magazineCover"
              class="magazine-cover h-100 d-flex align-end justify-center border outlined rounded-md"
            >
              <div class="d-flex">
                <v-btn
                  depressed
                  large
                  color="primary"
                  class="rounded-pill mb-3 mx-auto"
                  :to="magazineLink"
                  >{{ $t("leaflet.goToMagazine") }}</v-btn
                >
              </div>
            </v-img>
          </v-card>
        </v-col>
        <template v-if="loading">
          <v-col v-for="index in 12" :key="index" cols="12" sm="6" md="4">
            <v-skeleton-loader
              type="image, list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
        </template>
        <template v-else>
          <v-col
            cols="12"
            sm="4"
            md="4"
            lg="3"
            xl="2"
            v-for="article in articleList"
            :key="article.id"
          >
            <ArticleCard
              :key="article.articleId"
              :article="article"
              :show-opened="true"
              :show-read="false"
            />
          </v-col>
        </template>
        <v-row> </v-row>
      </v-row>
      <v-pagination
        v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
        :value="pager.selPage"
        :page="pager.selPage"
        :length="pager.totPages ? pager.totPages : 0"
        :totalVisible="6"
        @next.stop.prevent="handlePageFilter"
        @prev="handlePageFilter"
        @input="handlePageFilter"
      ></v-pagination>
      <NewsAlert :news="openedNews" v-if="openedNews != null" />
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.magazine-card {
  height: 220px;
  width: auto;
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb";
import article from "~/mixins/article";
import CategoryTitle from "@/components/category/CategoryTitle";
import ArticleCard from "./ArticleCard.vue";
import category from "~/mixins/category";
import mksCmService from "@/commons/service/mksCmService";
import leafletService from "~/service/leafletService.js";

export default {
  name: "ArticleList",
  mixins: [category, article],
  components: {
    CategoryTitle,
    ArticleCard,
    Breadcrumb
  },
  data() {
    return {
      magazines: null,
      loading: false,
      articleList: null,
      openedNews: null,
      pager: {},
      page: 1,
      key: 0,
      magazineCover: null,
      magazineLink: null
    };
  },
  computed: {
    showDialog() {
      return this.articleList.length > 0;
    }
  },
  methods: {
    async fetchNews(page) {
      this.loading = true;
      const res = await mksCmService.searchArticle({
        parent_article_type_id: 2,
        page: page,
        sort: "pub_date des"
      });
      if (res) {
        this.articleList = res.data.articles;
        this.pager = res.data.page;
        this.loading = false;
        this.key++;
      }
    },
    async fetchMagazines() {
      this.magazines = await leafletService.search({
        parent_leaflet_type_id: 2
      });
      if (this.magazines && this.magazines.length > 0) {
        this.magazines.sort((a, b) => {
          return new Date(b.fromDate) - new Date(a.fromDate);
        });

        console.log("MAGAZINES", this.magazines);
        const latestMagazine = this.magazines[0];
        this.magazineLink = "/rivista";
        this.magazineCover =
          latestMagazine.baseLocation + latestMagazine.firstPagePreview;
      }
    },
    // openNews(id) {
    //   console.log("ID", id);
    //   let clone = JSON.parse(JSON.stringify(id));
    //   this.openedNews = clone;
    // },
    async handlePageFilter(page) {
      let _this = this;
      // console.log(page);
      _this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      _this.fetchNews(page, 24);
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    }
  },
  async mounted() {
    await this.fetchNews();
    await this.fetchMagazines();
  }
};
</script>
