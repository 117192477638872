function buildArticleRoute(article) {
  let to;
  if (article.articleType.name === "page") {
    to = {
      name: "Category",
      params: {
        pathMatch: article.slug
      }
    };
  } else if (article.articleType.name === "cooperative") {
    to = {
      name: "CooperativeDetail",
      params: { slug: article.slug }
    };
  } else if (
    article.articleType.name === "post" ||
    article.articleType.name === "News"
  ) {
    to = {
      path: `/rivista-e-news/${article.slug}`
    };
  } else {
    to = {
      name: article.articleType.name,
      params: {
        pageName: article.slug
      }
    };
  }
  return to;
}

export { buildArticleRoute };
