const routes = [
  {
    path: "/negozi-e-volantini",
    meta: {
      breadCrumb: "Negozi e Volantini",
      site: "sait"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "WarehouseMap",
        meta: {
          site: "sait",
          slug: "negozi-e-volantini"
        },
        component: () => import("@/components/category/warehouse-map.vue")
      },
      {
        path: "/negozi-e-volantini/:slug",
        name: "WarehouseDetail",
        meta: {
          // breadCrumb: "Punto Vendita",
          slug: "negozi-e-volantini",
          site: "sait"
        },
        component: () => import("@/views/store/WarehouseDetail.vue")
      }
    ]
  }
  // {
  //   name: "ServiceAutodiscovery",
  //   path: "/service-discovery",
  //   meta: {
  //     breadCrumb: "Verifica il servizio",
  //     site: "sait"
  //   },
  //   props: true,
  //   component: () => import("@/components/store/ServiceAutodiscovery.vue")
  // }
];

export default routes;
