export default {
  theme: {
    themes: {
      light: {
        primary: {
          base: "#D9000D",
          lighten1: "#EB5861",
          lighten2: "#FFC5C9",
          darken1: "#BF030E",
          darken2: "#82070E"
        },
        secondary: {
          base: "#2B2F31",
          lighten1: "#044898",
          lighten2: "#2c81e5",
          darken1: "#022348",
          darken2: "#001227",
          darken3: "#022348"
        }
      }
    }
  }
};
