import store from "@/store";
let routes = [
  {
    path: "/reset-password-request",
    name: "ResetPasswordRequest",
    component: () => import("@/views/profile/ResetPasswordRequest.vue"),
    meta: {
      slug: "reset-password-request"
    }
  },
  {
    path: "/reset-password-confirm",
    name: "ResetPasswordConfirm",
    props: route => ({
      login: route.params.email || route.query?.email,
      queryToken: route.query?.token
    }),
    component: () => import("@/views/profile/ResetPasswordConfirm.vue"),
    meta: { slug: "reset-password-confirm" }
  },
  {
    path: "/reset-password-sent",
    name: "ResetPasswordSent",
    props: { showLogin: true },
    component: () => import("@/views/EbsnCategory.vue"),
    meta: { slug: "reset-password-sent" }
  },
  {
    path: "/registration/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/profile/ResetPassword.vue"),
    meta: { slug: "reset-password" },
    props: route => ({
      message: route.params.message,
      login: route.params.login || route.query?.login,
      queryToken: route.query?.token
    })
  },
  {
    path: "/reset-password-completed",
    name: "ResetPasswordCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    props: { showLogin: true },
    meta: { slug: "reset-password-completed" }
  },
  {
    path: "/thankyou-agreement",
    name: "RegistrationCompletedAgreement",
    component: () => import("@/views/profile/RegistrationCompleted.vue"),
    props: {
      mail: null,
      agreement: true
    },
    meta: { slug: "registration-confirm-agreement" }
  },
  {
    path: "/welcome-selfscanning",
    name: "WelcomeSelfScanning",
    component: () => import("@/views/profile/WelcomeSelfScanning.vue"),
    props: {
      mail: null,
      agreement: true
    },
    meta: { slug: "welcome-selfscanning" }
  },
  {
    path: "/registration-user",
    name: "RegistrationUser",
    component: () => import("@/views/profile/RegistrationUser.vue"),
    meta: { slug: "registration-user" }
  },
  {
    path: "/registration/confirm",
    name: "RegistrationConfirmed",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    meta: { slug: "registration/confirm" }
  },
  {
    path: "/registration/confirm/success",
    name: "RegistrationConfirmed",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    meta: { slug: "registration/confirm/success" }
  },
  {
    path: "/registration/confirm/already-confirmed",
    name: "RegistrationConfirmed",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    meta: { slug: "registration/confirm/already-confirmed" }
  },
  {
    path: "/registration/confirm/*",
    name: "RegistrationConfirmed",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    meta: { slug: "registration/confirm/error" }
  },
  {
    path: "/registration-social-completed",
    name: "RegistrationSocialCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    meta: { slug: "registration-social-completed" }
  },
  {
    path: "/registration/card-agreement",
    name: "CardAgreement",
    component: () => import("@/views/profile/CardAgreement.vue"),
    meta: { slug: ["registration/card-agreement", "card-agreement"] }
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      requiresAuth: true,
      breadCrumb: "Area personale",
      slug: "dashboard"
    },
    component: () => import("@/views/profile/Profile.vue"),
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        meta: { requiresAuth: true, breadCrumb: "Bacheca", slug: "dashboard" },
        component: () => import("@/views/profile/Dashboard.vue")
      },
      {
        path: "news-list",
        name: "NewsListPage",
        meta: {
          requiresAuth: true,
          breadCrumb: "Archivio Messaggi",
          slug: "news-list"
        },
        component: () => import("@/views/profile/NewsList.vue")
      },
      {
        path: "gifts",
        name: "ProfileGift",
        meta: {
          requiresAuth: true,
          breadCrumb: "Buoni sconto",
          slug: "gifts"
        },
        component: () => import("@/views/profile/ProfileGift.vue")
      },
      {
        path: "profile-update",
        name: "ProfileUpdate",
        props: route => ({
          emailchanged: route.query.emailchanged
        }),
        meta: {
          requiresAuth: true,
          breadCrumb: "Il mio profilo",
          slug: ["profile"]
        },
        component: () => import("@/views/profile/ProfileUpdate.vue")
      },
      {
        path: "profile-update-error",
        name: "ProfileUpdateError",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei dati",
          slug: ["profile-error", "profile/profile-update-error"]
        },
        component: () => import("@/views/profile/ProfileUpdateError.vue")
      },
      {
        path: "changepassword",
        name: "UpdatePassword",
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["change-password", "profile/changepassword"]
        },
        component: () => import("@/views/profile/UpdatePassword.vue")
      },
      {
        path: "profile-confirmed",
        name: "ProfileConfirmed",
        component: () => import("@/views/profile/ProfileConfirmed.vue"),
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["profile-confirmed", "profile/profile-confirmed"]
        }
      },
      {
        path: "profile-updated",
        name: "ProfileUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["profile-updated", "profile/profile-updated"]
        }
      },
      {
        path: "email-updated",
        name: "EmailUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: { slug: ["profile/email-updated", "email-updated"] }
      },
      {
        path: "invite",
        name: "Invite",
        meta: {
          requiresAuth: true,
          breadCrumb: "Invita i tuoi amici",
          slug: ["invite"]
        },
        component: () => import("@/views/profile/Invite.vue")
      },
      {
        path: "points-polv",
        name: "PointsPolv",
        meta: {
          requiresAuth: true,
          breadCrumb: "Polverizzazione punti",
          slug: ["points-polv"]
        },
        component: () => import("@/views/profile/PointsPolv.vue")
      },
      {
        path: "orders",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei ordini"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Orders",
            path: "",
            meta: {
              requiresAuth: true,
              slug: ["orders"]
            },
            component: () => import("@/views/profile/Orders.vue"),
            slug: ["profile/orders", "orders"]
          },
          {
            name: "Order",
            path: ":orderId",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio ordine",
              meta: ["order", "profile/orders/order"],
              slug: ["order"]
            },
            component: () => import("@/views/profile/Order.vue")
          }
        ]
      },
      {
        path: "order-refunds",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei resi"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Refunds",
            path: "",
            meta: {
              requiresAuth: true,
              slug: ["order-refunds"]
            },
            component: () => import("@/views/profile/Refunds.vue"),
            slug: ["profile/order-refunds", "order-refunds"]
          },
          {
            name: "Refund",
            path: ":refundId",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio reso",
              meta: ["order-refund", "profile/order-refunds/order-refund"],
              slug: ["order-refund"]
            },
            component: () => import("@/views/profile/RefundDetail.vue")
          }
        ]
      },
      {
        path: "order-refund-request",
        name: "RefundRequest",
        meta: {
          requiresAuth: true,
          breadCrumb: "Dettaglio reso",
          meta: [
            "order-refund-request",
            "profile/order-refunds/order-refund-request"
          ],
          slug: ["order-refund-request"]
        },
        component: () => import("@/views/profile/RefundRequest.vue")
      },
      {
        path: "lists",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le tue liste"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Lists",
            path: "",
            meta: {
              requiresAuth: true,
              meta: ["profile/lists", "lists"],
              slug: ["lists"]
            },
            component: () => import("@/views/profile/Lists.vue")
          },
          {
            path: ":listId",
            name: "List",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio lista"
            },
            component: () => import("@/views/profile/List.vue")
          }
        ]
      },
      {
        path: "preferred",
        name: "Favourites",
        meta: {
          requiresAuth: true,
          breadCrumb:
            store && store.getters["cart/hasFavorites"]
              ? "I miei preferiti"
              : "I più acquistati"
        },
        component: () => import("@/views/profile/Favourites.vue")
      },
      {
        path: "addresses",
        meta: { requiresAuth: true, breadCrumb: "I miei indirizzi" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Addresses",
            meta: {
              requiresAuth: true,
              slug: "profile/addresses"
            },
            component: () => import("@/views/profile/ProfileAddresses.vue")
          },
          {
            path: "manage/:addressId?",
            name: "EditAddress",

            meta: { requiresAuth: true, breadCrumb: "Dettaglio indirizzo" },
            component: () => import("@/views/profile/EditAddress.vue")
          }
        ]
      },
      {
        path: "payment-method",
        name: "MyPaymentMethods",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le mie carte",
          slug: ["payment-auths"]
        },
        component: () => import("@/views/profile/MyPaymentMethods.vue")
      },
      {
        path: "last-purchase",
        name: "LastPurchase",
        meta: {
          requiresAuth: true,
          breadCrumb: "Ultima spesa",
          slug: ["profile/last-purchase", "last-purchase"]
        },
        component: () => import("@/views/profile/LastPurchase.vue")
      },
      {
        path: "rewards-cart",
        name: "RewardsCart",
        meta: { requiresAuth: true, breadCrumb: "Carrello premi" },
        component: () => import("@/views/profile/RewardsCart.vue")
      },
      {
        path: "requested-rewards",
        name: "RequestedRewards",
        meta: { requiresAuth: true, breadCrumb: "Premi richiesti" },
        component: () => import("@/views/profile/RequestedRewards.vue")
      },
      {
        path: "create-offer",
        name: "CreateOffer",
        meta: { requiresAuth: true, breadCrumb: "Crea la tua offerta" },
        component: () => import("@/views/profile/RequestedRewards.vue")
      },
      {
        path: "donate",
        name: "Donate",
        meta: { requiresAuth: true, breadCrumb: "Dona i tuoi punti" },
        component: () => import("@/views/profile/Donate.vue")
      },
      {
        path: "paymentduedate",
        name: "PaymentDueDate",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le mie scadenze",
          slug: ["duedate"]
        },
        component: () => import("@/views/profile/PaymentDueDate.vue")
      },
      {
        path: "invoice-data",
        name: "InvoiceData",
        meta: {
          requiresAuth: true,
          breadCrumb: "Dati fatturazione",
          slug: ["duedate"]
        },
        component: () => import("@/views/profile/InvoiceData.vue")
      },
      {
        path: "fidelity",
        name: "Fidelity",
        meta: {
          requiresAuth: true,
          breadCrumb: "La mia tessera",
          slug: ["fidelity"]
        },
        component: () => import("@/views/profile/Fidelity.vue")
      }
    ]
  }
];

export default routes;
