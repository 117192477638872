<template>
  <v-container class="store-selector fill-height"
    ><v-layout fill-height column
      ><div class="d-flex justify-center">
        <img
          class="logo"
          :src="serviceImg"
          height="60"
          alt="Logo Coop Trentino"
        />
      </div>
      <h3 class="mt-3 mb-4 mb-sm-0 text-center">
        {{ $t("storeSelector.title") }}
      </h3>
      <p class="selector-subitle text-center">
        {{ $t("storeSelector.subtitle") }}
      </p>
      <v-row
        v-if="storeList.length"
        no-gutters
        justify="space-between"
        align="center"
        class="mb-8 tabs"
      >
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="store in storeList"
          :key="store.deliveryServiceId"
          @click="selectStore(store)"
        >
          <div
            class="tab d-flex flex-row flex-md-column align-center justify-center flex-grow-1 pa-1 mx-1 mb-1 mb-md-0"
            :class="store.storeId === selectedStore ? `selected` : ``"
          >
            <div
              class="d-flex flex-column align-center justify-center flex-grow-1"
            >
              <img
                class="logo"
                :src="`/img_layout/warehouse/${store.storeId}.png`"
                :alt="store.name"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <div v-if="selectedStore && showPrivacyTabs[selectedStore - 1]">
        <PrivacyOptions
          v-if="userData && selectedStore"
          :storeId="selectedStore"
          :userData="userData"
          @userDataUpdated="acceptAndSelectStore"
        />
      </div>
    </v-layout>
  </v-container>
</template>
<style lang="scss" scoped>
.tab {
  border: 1px solid var(--v-grey-lighten2);
  transition: border-color 0.3s ease;
  border-radius: 8px;
  background-color: var(--v-grey-lighten2);
  min-height: 150px;
  cursor: pointer;
  &.selected {
    border-color: var(--v-primary-base);
  }
}
.tab:hover {
  border-color: var(--v-primary-base);
}
</style>
<script>
import StoreService from "~/service/storeService";
import CustomService from "@/service/customService";
import UserService from "~/service/userService";
import cartInfo from "~/mixins/cartInfo";
import { mapState } from "vuex";
import PrivacyOptions from "../profile/form/PrivacyOptions.vue";
import analyticsService from "@/commons/service/analyticsService";
export default {
  name: "StoreSelector",
  mixins: [cartInfo],
  components: { PrivacyOptions },
  data() {
    return {
      storeList: [],
      disclaimerList: [],
      selectedStore: null,
      showPrivacyTabs: [],
      privacyFlag: [],
      userData: {}
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    async fetchStoreList() {
      this.selectedStore = this.cart.store.storeId;
      await StoreService.getStores().then(data => {
        this.storeList = data.stores;
      });
      if (
        !this.hasPrivacyForStore(
          this.userData.person.personInfos,
          this.selectedStore
        )
      ) {
        this.showPrivacyTabs = [false, false, false];
        this.showPrivacyTabs[this.selectedStore - 1] = true;
      }
    },
    async fetchDisclaimerListt() {
      await CustomService.getDisclaimerList().then(data => {
        this.disclaimerList = data.registrationDisclaimers;
      });
    },
    hasPrivacyForStore(personInfos, storeId) {
      let personInfoIdToEvaluate;
      for (let i = 0; i < this.disclaimerList.length; i++) {
        if (this.disclaimerList[i].storeId == storeId) {
          personInfoIdToEvaluate = this.disclaimerList[i].personInfoTypeId;
          break;
        }
      }

      for (let i = 0; i < personInfos.length; i++) {
        if (
          personInfos[i].personInfoTypeId == personInfoIdToEvaluate &&
          personInfos[i].value == "1"
        ) {
          return true;
        }
      }
      return false;
    },
    async selectStore(store) {
      if (this.selectedStore != store.storeId) {
        this.selectedStore = store.storeId;
        if (
          this.hasPrivacyForStore(
            this.userData.person.personInfos,
            this.selectedStore
          )
        ) {
          this.acceptAndSelectStore(store.storeId);
        } else {
          this.privacyFlag = ["0", "0", "0"];
          this.showPrivacyTabs = [false, false, false];
          this.showPrivacyTabs[this.selectedStore - 1] = true;
        }
      } else {
        this.$emit("submit", "setAddress");
      }
    },
    async acceptAndSelectStore(storeId) {
      let data = await this.$store.dispatch("cart/setStore", {
        storeId: storeId
      });
      if (data) {
        analyticsService.trackEvent(null, "coperativa", data.name);
        this.$emit("submit", "setAddress");
      }
    },
    fetchUserData() {
      UserService.getUserDetail().then(user => {
        this.userData = user;
      });
    },
    async reload() {
      await this.fetchUserData();
      await this.fetchStoreList();
    }
  },
  async created() {
    await this.fetchDisclaimerListt();
    await this.reload();
  }
};
</script>
